import React from 'react';

import { ContentListItem } from 'src/components';
import { ContentListItemCommonProps } from 'src/constants/types';

import type { Appendix } from '../types';

type Props = ContentListItemCommonProps<Appendix>;

export const AppendixCard: React.FC<Props> = React.memo<Props>(({ item, ...rest }) => {
  return <ContentListItem data={item} {...rest} />;
});
