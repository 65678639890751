import { forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { Animated, StyleSheet, View } from 'react-native';

import { Icon, MarkdownContent, Pressable } from 'src/components';
import { EXPAND_ANIMATION_DURATION } from 'src/constants/constants';
import { isAppendix } from 'src/helpers';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

import { NoteCard } from './NoteCard/NoteCard';
import { RemoveNoteConfirmModal } from './RemoveNoteConfirmModal';
import { MAIN_NOTE_SECTION_ID } from '../constants';
import { useNotesListContext } from '../context/NotesListContext';
import { isContentNote, isNewGeneralNotePlaceholder } from '../helpers';
import { ContentNote, Note } from '../types';

interface Props {
  note: ContentNote | Note;
  bottomBorder?: boolean;
  openByDefault: boolean;
  testID?: string;
}

export interface NotesListItemRef {
  close(): void;
}

export const NotesListItem = forwardRef<NotesListItemRef, Props>(
  ({ note, bottomBorder, openByDefault, testID }, ref) => {
    const isNewNote = isNewGeneralNotePlaceholder(note);
    const [isOpen, setIsOpen] = useState(openByDefault || isNewNote);
    const [isWarning, setIsWarning] = useState(false);

    const notesListContext = useNotesListContext();
    const animationController = useRef(new Animated.Value(openByDefault ? 1 : 0)).current;

    useLayoutEffect(() => {
      const config = {
        duration: EXPAND_ANIMATION_DURATION,
        toValue: isOpen ? 1 : 0,
        useNativeDriver: true,
      };
      Animated.timing(animationController, config).start();
    }, [isOpen, animationController]);

    const arrowTransform = animationController.interpolate({
      inputRange: [0, 1],
      outputRange: ['-180deg', '0deg'],
    });

    const getTitle = () => {
      if (isNewNote) {
        return i18n.t('notes:newNote');
      }

      if (isContentNote(note) && isAppendix({ contentType: note.contentType })) {
        return note.contentTitle;
      }

      if (isContentNote(note) && note.section === MAIN_NOTE_SECTION_ID) {
        return i18n.t('notes:general');
      }

      return note.title;
    };

    const handleHeaderPress = () => {
      if (notesListContext?.unsavedNotes.includes(note.id)) {
        setIsWarning(true);
      } else {
        setIsOpen((state) => !state);
      }
    };

    const handleWarningConfirm = () => {
      setIsOpen(false);
      setIsWarning(false);
    };

    useImperativeHandle(ref, () => ({
      close: () => {
        if (isNewNote) return;
        setIsOpen(false);
      },
    }));

    const header = (
      <View style={[styles.header, isOpen && styles.headerOpen]} testID={testID}>
        <MarkdownContent style={styles.title} inline limited>
          {getTitle()}
        </MarkdownContent>
        {!isNewNote && (
          <Animated.View style={{ transform: [{ rotate: arrowTransform }] }}>
            <Icon name="chevron-up" color={palette.navy} width={10} />
          </Animated.View>
        )}
      </View>
    );

    return (
      <>
        <View style={bottomBorder && styles.wrapperWithBorder} testID="notes-list-item">
          {isNewNote ? header : <Pressable onPress={handleHeaderPress}>{header}</Pressable>}
          {isOpen && <NoteCard type="existing" note={note} style={styles.card} />}
        </View>
        {!!isWarning && (
          <RemoveNoteConfirmModal
            cancel={() => setIsWarning(false)}
            type="navigation"
            confirm={handleWarningConfirm}
          />
        )}
      </>
    );
  },
);

const styles = StyleSheet.create({
  wrapperWithBorder: {
    borderBottomWidth: 1,
    borderBottomColor: palette.grey2,
  },
  header: {
    paddingVertical: 11,
    marginHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerOpen: {
    borderBottomWidth: 1,
    borderBottomColor: palette.grey2,
  },
  title: {
    color: palette.navy,
    ...typography.body3SemiBold,
  },
  card: {
    borderWidth: 0,
  },
});
