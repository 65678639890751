import { Target } from '@nandorojo/anchor';
import * as R from 'ramda';
import React, { ReactNode } from 'react';
import { StyleProp, TextStyle, View, StyleSheet, Platform, ViewStyle } from 'react-native';
import Markdown, { ASTNode, RenderRules } from 'react-native-markdown-display';

import { DEFAULT_MAX_FONT_SIZE_MULTIPLIER, HEADING_DATASET_KEY } from 'src/constants/constants';
import { DxTxMediaLink } from 'src/features/dxTx/components';
import { isAndroid, isWeb, Url } from 'src/helpers';
import {
  markdownItInstance,
  limitedMarkupMarkdownItInstance,
  SUP_TAG,
  SUB_TAG,
  UNDERLINE_TAG,
  UPDATED_CONTENT_TAG,
} from 'src/helpers/markdown';
import { createHeadingId } from 'src/helpers/markdown/utils';
import { useFontScale } from 'src/hooks/useFontScale';
import { Link } from 'src/navigation/components';
import { EnvService } from 'src/services/EnvService';
import { palette, fonts, typography, ifWeb } from 'src/styles';

import { ListItem } from './ListItem';
import { SupSub } from './SupSub';
import { Table } from './Table';
import { Td } from './Td';
import { Th } from './Th';
import { StyledText } from '../StyledText';

interface Props {
  style?: StyleProp<TextStyle>;
  /** Removes wrappers from paragraphs and textgroups; wraps everything in StyledText instead of View */
  inline?: boolean;
  horizontalTableRightPadding?: number;
  maxFontSizeMultiplier?: number;
  heading1Style?: StyleProp<TextStyle>;
  linkStyle?: StyleProp<TextStyle>;
  singleLine?: boolean;
  /** Disables some of the features (such as links, lists or tables) */
  limited?: boolean;
  testID?: string;
  children?: string | null;
  disableAppendixLinks?: boolean;
}

const PARAGRAPH_MARGIN = 4;
const DEFAULT_FONT_SIZE = typography.text2.fontSize!;

export const MarkdownContent: React.FC<Props> = React.memo(
  ({
    children = '',
    style = {},
    inline,
    horizontalTableRightPadding = 0,
    heading1Style,
    linkStyle,
    singleLine,
    limited,
    testID,
    disableAppendixLinks,
    maxFontSizeMultiplier = DEFAULT_MAX_FONT_SIZE_MULTIPLIER,
  }) => {
    const markdownit = limited ? limitedMarkupMarkdownItInstance : markdownItInstance;
    const fontScale = useFontScale();

    const {
      fontSize = DEFAULT_FONT_SIZE,
      lineHeight = Math.floor(fontSize * 1.3),
      ...styles
    } = StyleSheet.flatten(style);

    const listMarkerStyle: TextStyle = {
      fontSize,
      lineHeight,
    };

    const ulMarkerStyle: TextStyle = {
      fontSize: fontSize * 1.25,
    };

    const itemListContainerStyle: ViewStyle = {
      marginVertical: PARAGRAPH_MARGIN,
    };

    const systemFontScale = Math.min(fontScale, DEFAULT_MAX_FONT_SIZE_MULTIPLIER);
    const supSubFontSize = Math.floor(fontSize * 0.75);
    // <View>s inside <Text> are positioned slightly lower on iOS for some reason
    const supSubPlatformOffset = Platform.select({
      ios: -Math.floor(lineHeight * systemFontScale * 0.1),
      default: 0,
    });
    const subOffset = Math.floor(lineHeight * systemFontScale * 0.5);

    const rules: RenderRules = {
      body: (node, children, _parent, styles) => (
        <View key={node.key} style={styles._VIEW_SAFE_body} testID={testID}>
          {children}
        </View>
      ),
      [SUP_TAG]: (node, children) => {
        if (isWeb) {
          return <sup key={node.key}>{children}</sup>;
        }

        return (
          <SupSub key={node.key} verticalOffset={supSubPlatformOffset}>
            {children}
          </SupSub>
        );
      },
      [SUB_TAG]: (node, children) => {
        if (isWeb) {
          return <sub key={node.key}>{children}</sub>;
        }

        return (
          <SupSub key={node.key} verticalOffset={subOffset + supSubPlatformOffset}>
            {children}
          </SupSub>
        );
      },
      [UNDERLINE_TAG]: (node, children, _, styles) => (
        <StyledText key={node.key} style={styles.underline} noDefaultStyle>
          {children}
        </StyledText>
      ),
      [UPDATED_CONTENT_TAG]: (node, children, _, styles) => (
        <StyledText key={node.key} style={styles.updated_content} noDefaultStyle>
          {children}
        </StyledText>
      ),
      list_item: (node, children, parent) => (
        <ListItem
          key={node.key}
          parent={parent}
          node={node}
          markerStyle={listMarkerStyle}
          ulMarkerStyle={ulMarkerStyle}
          containerStyle={itemListContainerStyle}
        >
          {children}
        </ListItem>
      ),
      table: (node, children) => (
        <Table horizontalTableRightPadding={horizontalTableRightPadding} key={node.key}>
          {children}
        </Table>
      ),
      // we need to remove all the containers around cells to achieve proper sizing for horizontal layout
      // see https://css-tricks.com/accessible-simple-responsive-tables/#responsive-tables-with-flexbox
      thead: (_, children) => children,
      tbody: (_, children) => children,
      strong: (node, children, _, styles) => (
        <StyledText key={node.key} style={styles.strong} noDefaultStyle>
          {children}
        </StyledText>
      ),
      textgroup: (node, children, _, styles) => (
        <StyledText key={node.key} style={styles.textgroup} noDefaultStyle>
          {children}
        </StyledText>
      ),
      heading1: (node, children, _, styles) => createHeadingComponent(styles.heading1)(node, children),
      heading2: (node, children, _, styles) => createHeadingComponent(styles.heading2)(node, children),
      heading3: (node, children, _, styles) => createHeadingComponent(styles.heading3)(node, children),
      text: (node, _children, _, _styles, inheritedStyles = {}) => {
        // we use inheritedStyles only for Android devices which
        // doesn't go together with <MarkdownContent/> and nested <Text/>.
        // In addition, inheritedStyles breaks tranistionProperty in <Text> on Web
        return (
          <StyledText
            maxFontSizeMultiplier={maxFontSizeMultiplier}
            key={node.key}
            style={(!inline || isAndroid) && inheritedStyles}
            noDefaultStyle
          >
            {node.content}
          </StyledText>
        );
      },
      paragraph: (node, children, _, styles) => (
        <StyledText key={node.key} style={styles.paragraph} noDefaultStyle>
          {children}
        </StyledText>
      ),
      tr: (_, children) => children,
      th: (node, children, parent) => (
        <Th columnsNumber={parent[0].children.length} isNodeFirst={node.index === 0} key={node.key}>
          {children}
        </Th>
      ),
      td: (node, children, parent) => (
        <Td
          key={node.key}
          isNodeFirst={node.index === 0}
          columnsNumber={parent[0].children.length}
          isRowEven={!!(parent[0].index % 2)}
        >
          {children}
        </Td>
      ),
      link: (node, children, _, styles) => {
        const href = node.attributes.href as string;
        const clinicalImageReg = /^https?:\/\/(\w+)\/?$/g;
        const clinicalImageMatch = clinicalImageReg.exec(href || '');
        if (clinicalImageMatch) {
          const id = clinicalImageMatch[1];
          return (
            <DxTxMediaLink key={node.key} id={id}>
              {children}
            </DxTxMediaLink>
          );
        }
        const isAppendixLink = href.includes('appendix');

        if (isAppendixLink && disableAppendixLinks) {
          return (
            <StyledText key={node.key} style={styles.paragraph} noDefaultStyle>
              {children}
            </StyledText>
          );
        }

        const scheme = EnvService.getEnv('SCHEME');
        const domain = EnvService.getEnv('DOMAIN');
        const url = new Url(href, `${scheme}://${domain}`);
        const isInternalLink = url.host === domain;

        const linkProps = isInternalLink
          ? { to: url.pathname + url.search }
          : { to: url.href, external: true };

        return (
          <Link key={node.key} style={styles.link} wrapper="text" {...linkProps}>
            <>{children}</>
          </Link>
        );
      },
    };

    const inlineRules: RenderRules = {
      ...rules,
      body: (node, children, _parent, styles) => (
        <StyledText key={node.key} style={styles.body} singleLine={singleLine} testID={testID}>
          {children}
        </StyledText>
      ),
      paragraph: (_, children) => children,
      textgroup: (_, children) => children,
    };

    return (
      <Markdown
        markdownit={markdownit}
        style={{
          body: {
            fontFamily: fonts.sourceSans,
            fontSize,
            lineHeight,
            ...styles,
          },
          em: {
            paddingLeft: 1,
          },
          strong: {
            fontFamily: fonts.sourceSans,
            ...typography.weightSemiBold,
          },
          textgroup: {
            maxWidth: '100%',
          },
          blockquote: {
            backgroundColor: palette.grey1,
            borderColor: palette.red2,
            borderRadius: 5,
            paddingHorizontal: 16,
            paddingVertical: 8,
            marginVertical: 8,
            borderLeftWidth: 8,
          },
          link: {
            color: palette.blue,
            ...typography.weightSemiBold,
            ...StyleSheet.flatten(linkStyle),
          },
          paragraph: {
            marginTop: PARAGRAPH_MARGIN,
            marginBottom: PARAGRAPH_MARGIN,
          },
          sub: {
            fontSize: supSubFontSize,
            ...ifWeb({
              lineHeight: 0,
            }),
          },
          sup: {
            fontSize: supSubFontSize,
            ...ifWeb({
              lineHeight: 0,
            }),
          },
          underline: {
            textDecorationLine: 'underline',
          },
          updated_content: {
            color: palette.darkBlue,
            ...typography.weightSemiBold,
          },
          heading1: {
            ...typography.body2SemiBold,
            ...StyleSheet.flatten(heading1Style),
          },
          heading2: {
            ...typography.body2SemiBold,
            color: palette.grey6,
            marginTop: 10,
            marginBottom: 8,
          },
          heading3: {
            ...typography.body2,
            fontStyle: 'italic',
            marginTop: 10,
            marginBottom: 8,
          },
          /* I had to leave those typography here since Markdown is injecting styles as default into children */
          th: { ...typography.body1ShortBold },
          td: { ...typography.body1Short },
          hr: {
            backgroundColor: palette.grey2,
            marginVertical: 8,
          },
        }}
        rules={inline ? inlineRules : rules}
      >
        {children}
      </Markdown>
    );
  },
  R.equals,
);

const createHeadingComponent = (style: StyleProp<TextStyle>) => {
  return (node: ASTNode, children: ReactNode) => {
    const headingId = createHeadingId(node.children[0].children[0].content);
    return (
      <Target name={headingId} key={node.key}>
        <StyledText
          key={node.key}
          style={style}
          dataSet={{
            [HEADING_DATASET_KEY]: headingId,
          }}
        >
          {children}
        </StyledText>
      </Target>
    );
  };
};
