import { debounce } from 'debounce';
import { useEffect, useMemo, useState } from 'react';

export const useDebouncedValue = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const handleChange = useMemo(() => debounce(setDebouncedValue, delay), [delay]);

  useEffect(() => {
    handleChange(value);
  }, [value, handleChange]);

  return [debouncedValue, handleChange] as const;
};
