import React from 'react';
import { StyleSheet, Text, TextProps } from 'react-native';

import { DEFAULT_MAX_FONT_SIZE_MULTIPLIER } from 'src/constants/constants';

import { fonts, palette } from '../styles';

interface Props extends Omit<React.PropsWithChildren<TextProps>, 'numberOfLines'> {
  singleLine?: boolean;
  noDefaultStyle?: boolean;
}

export const StyledText = React.forwardRef<Text, Props>(
  (
    {
      maxFontSizeMultiplier = DEFAULT_MAX_FONT_SIZE_MULTIPLIER,
      style,
      testID,
      singleLine,
      noDefaultStyle,
      ...rest
    },
    ref,
  ) => {
    return (
      // eslint-disable-next-line react/forbid-elements
      <Text
        {...rest}
        ref={ref}
        style={[!noDefaultStyle && styles.text, style]}
        maxFontSizeMultiplier={maxFontSizeMultiplier}
        testID={testID}
        numberOfLines={singleLine ? 1 : undefined}
      />
    );
  },
);

StyledText.displayName = 'StyledText';

export const styles = StyleSheet.create({
  text: {
    fontFamily: fonts.sourceSans,
    color: palette.grey8,
  },
});
