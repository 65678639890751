import { Permission } from 'src/features/auth/enums';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';

import { SearchContentTypeFilter } from './enums';
import type { FilterConfigItem } from './types';

export const searchFiltersConfig: Record<SearchContentTypeFilter, FilterConfigItem> = {
  [SearchContentTypeFilter.All]: {
    text: i18n.t('search:all'),
  },
  [SearchContentTypeFilter.Drug]: {
    text: i18n.t('search:drugs'),
    permission: Permission.FullAccessBasicContentTypes,
  },
  [SearchContentTypeFilter.Handout]: {
    text: i18n.t('search:handouts'),
  },
  [SearchContentTypeFilter.DxTx]: {
    text: i18n.t('search:clinical-briefs'),
    permission: Permission.FullAccessClinicalBriefs,
  },
  [SearchContentTypeFilter.Algorithm]: {
    text: i18n.t('search:algorithm'),
    permission: Permission.FullAccessAlgorithms,
  },
  [SearchContentTypeFilter.Appendix]: {
    text: i18n.t('search:appendix'),
    permission: Permission.FullAccessBasicContentTypes,
  },
  [SearchContentTypeFilter.DDx]: {
    text: i18n.t('search:ddx'),
    permission: Permission.FullAccessDDx,
  },
};

export const webScreensWithSearchBar = [
  Route.Home,
  Route.DrugsList,
  Route.DrugItem,
  Route.AppendixList,
  Route.AppendixItem,
  Route.DrugHandoutsList,
  Route.ClinicalHandoutsList,
  Route.DIC,
  Route.Search,
  Route.SearchWeb,
  Route.Algorithms,
  Route.DxTxList,
  Route.DxTxItem,
  Route.ClinicalHandoutItem,
  Route.DrugHandoutItem,
];

export const MIN_SEARCH_QUERY_LENGTH = 3;
