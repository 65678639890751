import type { ButtonColors } from 'src/components';
import { palette } from 'src/styles';

import { ContentListType, ContentStatus, ItemListByLetterFetchParams } from './types';

export const quickLinkButtonColors: ButtonColors = {
  default: { primary: palette.grey2, content: palette.blue },
  active: { primary: palette.grey4, content: palette.navy },
  disabled: { primary: palette.grey2, content: palette.grey4 },
};

export const featureButtonColors: ButtonColors = {
  default: { content: palette.navy, primary: palette.grey2 },
  active: { content: palette.blue, primary: palette.grey4 },
  disabled: { content: palette.grey2, primary: palette.grey2 },
};

export const PUBLICATION_DETAILS_SECTION_ID = 'publication_details';
export const SECTIONS_WITHOUT_NOTES = [PUBLICATION_DETAILS_SECTION_ID];

export const UPDATED_LIST_PARAM = Object.freeze({ status: ContentStatus.Update });
export const NEW_LIST_PARAM = Object.freeze({ status: ContentStatus.New });

export const contentQueryKeys = {
  allLists: ['content-list'] as const,
  list: (type: ContentListType, params?: Partial<ItemListByLetterFetchParams>) =>
    [...contentQueryKeys.allLists, type, params] as const,
};

export const OVERVIEW_TOC_ITEM_ID = 'default-section';
