import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { ContentStatus } from 'src/constants/types';
import { i18n } from 'src/locale';
import { palette } from 'src/styles';

import { Badge } from './Badge';

interface Props {
  statusType: ContentStatus;
  textColor?: string;
  style?: StyleProp<ViewStyle>;
}

export const StatusBadge: React.FC<Props> = ({ statusType, textColor, style }) => {
  const badgeTextColor = textColor ? textColor : palette.grey5;

  const getContentStatusText = (statusType: ContentStatus) => {
    switch (statusType) {
      case ContentStatus.InDevelopment:
        return i18n.t('comingSoon');
      case ContentStatus.New:
        return i18n.t('new');
      case ContentStatus.Update:
        return i18n.t('updated');
      case ContentStatus.Beta:
        return i18n.t('beta');
      default:
        return statusType;
    }
  };

  return (
    <Badge
      badgeText={getContentStatusText(statusType)}
      textStyle={[
        {
          color: badgeTextColor,
        },
        statusType === ContentStatus.Beta && styles.transformNone,
      ]}
      style={style}
    />
  );
};

const styles = StyleSheet.create({
  transformNone: {
    textTransform: 'none',
  },
});
