import React, { useState, useCallback, useRef } from 'react';
import { View, StyleSheet } from 'react-native';

import { MenuTogglerButton } from 'src/components/MenuTogglerButton';
import { useOutsideClickDetector } from 'src/hooks';
import { NavigationMenuItem } from 'src/navigation/types';
import { ifWeb, palette } from 'src/styles';

import { NavigationButtons } from '../NavigationButtons';
import { NavigationDropdown } from '../NavigationDropdown/NavigationDropdown';

interface Props {
  items: NavigationMenuItem[];
}

export const MenuMobile: React.FC<Props> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownWrapper = useRef(null);
  const buttonWrapper = useRef(null);

  const toggleMenu = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  useOutsideClickDetector([dropdownWrapper, buttonWrapper], () => {
    isOpen && closeMenu();
  });

  return (
    <>
      <View style={styles.container} ref={buttonWrapper}>
        <MenuTogglerButton onPress={toggleMenu} isOpen={isOpen} />
      </View>
      <View ref={dropdownWrapper} style={styles.menu}>
        <NavigationDropdown
          close={closeMenu}
          items={items}
          isDisplayed={isOpen}
          additionalContent={<NavigationButtons inMobileDropdown />}
          containerStyles={styles.dropdown}
        />
      </View>
      {isOpen && <View style={styles.overlay} />}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 20,
  },
  menu: {
    left: 0,
    top: '100%',
    zIndex: 10,
    position: 'absolute',
  },
  overlay: {
    ...ifWeb({
      width: '100vw',
      height: '100vh',
      backgroundColor: palette.black,
      opacity: 0.2,
      position: 'absolute',
      top: '100%',
      left: 0,
    }),
  },
  dropdown: {
    width: 500,
    ...ifWeb({
      maxWidth: '100vw',
    }),
  },
});
