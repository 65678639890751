import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OVERVIEW_TOC_ITEM_ID } from 'src/constants/content';
import type { ContentItemDetails, TableOfContentsItem } from 'src/constants/types';
import { MixpanelEvent, MixpanelService, getMixpanelTOCParams } from 'src/features/tracking';
import { isItemWithSections, shouldItemHaveTableOfContents } from 'src/helpers';

interface Params {
  item: ContentItemDetails;
  scrollToTop(): void;
  onItemPress(sectionId: string): void;
}

export const useTableOfContentsItems = ({ item, scrollToTop, onItemPress }: Params) => {
  const { t } = useTranslation('contents');

  const shouldTOCBeDisplayed = shouldItemHaveTableOfContents(item);

  const defaultItem: TableOfContentsItem = useMemo(
    () => ({
      title: t('contents:overview'),
      onPress: scrollToTop,
      id: OVERVIEW_TOC_ITEM_ID,
    }),
    [scrollToTop, t],
  );

  const handleItemPress = useCallback(
    (sectionId: string, sectionTitle: string) => {
      const mixpanelEventParams = getMixpanelTOCParams(item, sectionTitle);
      if (mixpanelEventParams) {
        MixpanelService.track(MixpanelEvent.ToCLinkClicked, mixpanelEventParams);
      }
      onItemPress(sectionId);
    },
    [onItemPress, item],
  );

  const tocItems = useMemo(() => {
    if (!shouldTOCBeDisplayed || !isItemWithSections(item)) return [];

    const sectionItems: TableOfContentsItem[] = item.sections
      .filter((item) => !!item.title)
      .map((section) => ({
        id: section.id,
        title: section.title,
        onPress: () => handleItemPress(section.id, section.title),
      }));

    return [defaultItem, ...sectionItems];
  }, [shouldTOCBeDisplayed, item, handleItemPress, defaultItem]);

  return tocItems;
};
