import React from 'react';

import { ContentListItem } from 'src/components';
import { ContentListItemCommonProps } from 'src/constants/types';

import type { DxTx, DxTxDetails } from '../types';

type Props = ContentListItemCommonProps<DxTx | DxTxDetails>;

export const DxTxCard: React.FC<Props> = React.memo<Props>(({ item, ...rest }) => {
  return <ContentListItem data={item} {...rest} />;
});
