import { ContentType } from 'src/constants/types';
import {
  isAlgorithm,
  isDxTx,
  isClinicalHandout,
  isDrugHandout,
  isAppendix,
  isDrug,
  isDDx,
} from 'src/helpers/content';

import { GroupRole, Permission } from './enums';
import type { GroupInfo, GroupInfoCurrentMember } from './types';
import { GroupMemberAccessLevel } from '../adminDashboard/types';

// note that admins are also group members
export const isUserAGroupMember = (groupInfo: GroupInfo | null): groupInfo is GroupInfoCurrentMember => {
  return !!groupInfo && !groupInfo.removed;
};

export const isUserAGroupAdmin = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.role === GroupRole.ADMIN;
};

export const isUserANormalGroupMember = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.role === GroupRole.MEMBER;
};

/** User is a group member with limited access to content */
export const doesUserHaveLimitedProAccess = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.accessLevel === GroupMemberAccessLevel.ProLimited;
};

export const isUserAManagedGroupMember = (
  groupInfo: GroupInfo | null,
): groupInfo is GroupInfoCurrentMember => {
  return isUserAGroupMember(groupInfo) && !!groupInfo.isManagedGroup;
};

export const isUserAManagedGroupAdmin = (groupInfo: GroupInfo | null): boolean => {
  return isUserAManagedGroupMember(groupInfo) && groupInfo.role === GroupRole.ADMIN;
};

export function getPermissionsByContentType(contentType: ContentType): {
  access: Permission;
  display: Permission;
} {
  if (isAlgorithm({ contentType })) {
    return {
      access: Permission.FullAccessAlgorithms,
      display: Permission.DisplayAlgorithms,
    };
  }
  if (isDxTx({ contentType })) {
    return {
      access: Permission.FullAccessClinicalBriefs,
      display: Permission.DisplayClinicalBriefs,
    };
  }
  if (isClinicalHandout({ contentType })) {
    return {
      access: Permission.FullAccessClinicalHandouts,
      display: Permission.DisplayClinicalHandouts,
    };
  }
  if (isDrugHandout({ contentType })) {
    return {
      access: Permission.FullAccessDrugHandouts,
      display: Permission.FullAccessDrugHandouts,
    };
  }

  if (isAppendix({ contentType })) {
    return {
      access: Permission.FullAccessBasicContentTypes,
      display: Permission.FullAccessBasicContentTypes,
    };
  }

  if (isDrug({ contentType })) {
    return {
      access: Permission.FullAccessBasicContentTypes,
      display: Permission.FullAccessBasicContentTypes,
    };
  }

  if (isDDx({ contentType })) {
    return {
      access: Permission.FullAccessDDx,
      display: Permission.DisplayDDx,
    };
  }

  throw new Error(`Couldn't find permissions for ${contentType} content type`);
}
