import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { StyledText } from 'src/components';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

import { getClassificationData } from '../helpers';
import { DICClassificationKey } from '../types';

interface ArrowProps {
  colorLeft: string;
  colorRight: string;
}

const Arrow: React.FC<ArrowProps> = ({ colorLeft, colorRight }) => (
  <Svg width={9} height={28} style={styles.arrow}>
    <Path d="m0 28 6.99-14-6.99-14z" fill={colorLeft} />
    <Path d="m1.968 0 6.99 14-6.99 14h8.032v-28z" fill={colorRight} />
  </Svg>
);

export interface Props {
  type: DICClassificationKey;
  style?: StyleProp<ViewStyle>;
}

export const DICLegendLabel: React.FC<Props> = ({ type, style }) => {
  const classificationData = getClassificationData(type);

  if (!classificationData) {
    return null;
  }

  const backgroundColor = classificationData.color;
  const badgeStyle = [styles.badge, { backgroundColor }];

  return (
    <View style={[styles.wrapper, style]}>
      {classificationData.useWithCaution ? (
        <>
          <View style={[styles.badge, styles.cautionBadge]}>
            <StyledText maxFontSizeMultiplier={1} style={styles.text} singleLine>
              {i18n.t('dic:useWithCaution')}
            </StyledText>
          </View>
          <Arrow colorLeft={palette.darkOrange} colorRight={backgroundColor} />
          <View style={badgeStyle} testID="dic-classification-label">
            <StyledText maxFontSizeMultiplier={1} style={styles.text} singleLine>
              {classificationData.label}
            </StyledText>
          </View>
        </>
      ) : (
        <View style={badgeStyle} testID="dic-classification-label">
          <StyledText maxFontSizeMultiplier={1} style={styles.text} singleLine>
            {classificationData.label}
          </StyledText>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    flexShrink: 1,
  },
  badge: {
    height: 28,
    paddingVertical: 4,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 1,
  },
  arrow: {
    flexShrink: 0,
  },
  cautionBadge: {
    backgroundColor: palette.darkOrange,
    flexDirection: 'row',
  },
  text: {
    textTransform: 'uppercase',
    color: palette.white,
    ...typography.text2,
    ...typography.weightBold,
  },
});
