import React from 'react';
import { StyleSheet, View } from 'react-native';

import { AccordionLegacy, StyledText } from 'src/components';
import { AccordionItemParams } from 'src/constants/types/accordion';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

import { DICLegendLabel } from './DICLegendLabel';
import { interactionClassifications } from '../constants';

export const ClassificationKey: React.FC = () => {
  const { isTablet } = useDeviceInfo();

  const items: AccordionItemParams[] = [
    {
      Label: <StyledText style={styles.accordionLabel}>{i18n.t('dic:classificationKey')}</StyledText>,
      Content: (
        <View>
          {interactionClassifications.map(({ classificationKey, description }, index) => (
            <View
              style={[styles.legendItem, index === 0 && styles.legendItemFirst]}
              key={classificationKey}
            >
              <DICLegendLabel type={classificationKey} style={styles.label} />
              <StyledText style={styles.descriptionText}>{description}</StyledText>
            </View>
          ))}
        </View>
      ),
      id: 'key',
    },
  ];

  return <AccordionLegacy items={items} fullWidthMode={!isTablet} />;
};

const styles = StyleSheet.create({
  legendItem: {
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: palette.grey2,
  },
  legendItemFirst: {
    borderTopWidth: 0,
  },
  label: {
    marginBottom: 12,
  },
  descriptionText: {
    ...typography.body2,
    color: palette.grey8,
  },
  accordionLabel: {
    ...typography.body3,
  },
});
