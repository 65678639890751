import mixpanel, { Dict, OverridedMixpanel } from 'mixpanel-browser';

import { isTest } from 'src/helpers/common';
import { EnvService } from 'src/services/EnvService';

import type { IMixpanelService, MixpanelEvent, MixpanelEventParams } from '../types';

declare global {
  interface Window {
    mixpanel?: OverridedMixpanel;
  }
}

class MixpanelService implements IMixpanelService {
  token: string | undefined;
  isEnabled: boolean;

  constructor() {
    this.token = EnvService.getEnv('MIXPANEL_TOKEN');
    this.isEnabled = !!this.token && !isTest;
  }

  async initialize() {
    if (this.isEnabled) {
      mixpanel.init(this.token!);
      // Appcues needs Mixpanel to be available as global variable to track custom events
      window.mixpanel = mixpanel;
    }
  }

  track<T extends MixpanelEvent>(eventName: T, params?: MixpanelEventParams[T]) {
    this.isEnabled && mixpanel.track(eventName, params as Dict | undefined);
  }

  identify(id: string) {
    this.isEnabled && mixpanel.identify(id);
  }

  clearUser() {
    this.isEnabled && mixpanel.reset();
  }

  setUserProperties(data: Record<string, any>) {
    this.isEnabled && mixpanel.people.set(data);
  }

  setSuperProperties(data: Record<string, string | undefined>) {
    this.isEnabled && mixpanel.register(data);
  }

  setGroup(groupKey: string, groupId: string) {
    if (this.isEnabled) {
      mixpanel.register({ [groupKey]: groupId });
    }
  }

  unsetGroup(groupKey: string) {
    this.isEnabled && mixpanel.unregister(groupKey);
  }
}

const instance = new MixpanelService();

export { instance as MixpanelService };
