import React, { useState, useEffect, useRef, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';

import { FullScreenTemplate, Container, ConfirmationScreen } from 'src/components';
import { ScrollableWrapper, ScrollableWrapperRef } from 'src/components/ScrollableWrapper';
import { i18n } from 'src/locale';

import {
  GroupInvitationRegistrationContextProvider,
  useGroupInvitationRegistrationFormContext,
} from '../../forms/groupInvitationRegistrationForm';
import { RegistrationFormStep } from '../../forms/types';
import { CompleteYourProfile } from '../CompleteYourProfile';
import { CreateAnAccount } from '../CreateAnAccount';
import { TermsAndConditionsCheck } from '../TermsAndConditionsCheck';

interface Props {
  email: string;
  memberId: string;
}

const Form: React.FC = () => {
  const { on, email, isSubmitting } = useGroupInvitationRegistrationFormContext();
  const [isFinished, setIsFinished] = useState(false);
  const [termsAccepted, setTerms] = useState(false);

  const wrapperRef = useRef<ScrollableWrapperRef>(null!);

  const scrollToStep = useCallback((step: RegistrationFormStep) => {
    wrapperRef.current.scrollToSection(step);
  }, []);

  const handleStepSubmit = useCallback(
    (step: RegistrationFormStep) => {
      if (step === RegistrationFormStep.CreateAnAccount) {
        setTimeout(() => {
          scrollToStep(RegistrationFormStep.CompleteYourProfile);
        }, 50);
      }
    },
    [scrollToStep],
  );

  useEffect(() => {
    const unsubscribeFns = [
      on('form-submitted', () => setIsFinished(true)),
      on('step-submitted', ({ step }) => handleStepSubmit(step)),
      on('step-error', ({ step }) => scrollToStep(step)),
    ];

    return () => {
      unsubscribeFns.forEach((fn) => fn());
    };
  }, [on, handleStepSubmit, scrollToStep]);

  if (isFinished) {
    return (
      <ConfirmationScreen
        icon="checkmark"
        title={i18n.t('groupInvitation:thankYouForRegistering')}
        body={i18n.t('groupInvitation:registrationConfirmed')}
        email={email}
      />
    );
  }

  return (
    <FullScreenTemplate title={i18n.t('screens:groupInvitationRegister')}>
      <Container verticalPadding>
        <ScrollableWrapper ref={wrapperRef}>
          <ScrollableWrapper.Section id={RegistrationFormStep.CreateAnAccount}>
            <CreateAnAccount stepNo={1} groupInvitationForm />
          </ScrollableWrapper.Section>
          <ScrollableWrapper.Section id={RegistrationFormStep.CompleteYourProfile}>
            <CompleteYourProfile
              stepNo={2}
              submitLabel={i18n.t('register')}
              submitLoading={isSubmitting}
              submitDisabled={!termsAccepted}
              additionalContent={
                <View style={styles.termsWrapper}>
                  <TermsAndConditionsCheck isChecked={termsAccepted} onChange={setTerms} />
                </View>
              }
            />
          </ScrollableWrapper.Section>
        </ScrollableWrapper>
      </Container>
    </FullScreenTemplate>
  );
};

export const GroupInvitationRegisterForm: React.FC<Props> = ({ email, memberId }) => {
  return (
    <GroupInvitationRegistrationContextProvider email={email} memberId={memberId}>
      <Form />
    </GroupInvitationRegistrationContextProvider>
  );
};

const styles = StyleSheet.create({
  termsWrapper: {
    marginTop: 20,
  },
});
