import { ContentType } from 'src/constants/types';
import {
  clinicalBriefsContentTypes,
  clinicalHandoutsContentTypes,
  drugHandoutContentTypes,
  isContentPro,
} from 'src/helpers';

import { getPermissionsByContentType } from '../../auth/helpers';
import { type UserPermissions } from '../../auth/types';

interface FilterConfig {
  labelI18nKey: string;
  labelInListI18nKey?: string;
  disableTextTransform?: boolean;
  contentTypes?: readonly ContentType[];
  id: string;
}

export class FavoritesFilter {
  private readonly config: FilterConfig;
  id: string;
  labelI18nKey: string;
  labelInListI18nKey?: string;
  disableTextTransform?: boolean;

  constructor(config: FilterConfig) {
    this.config = config;
    this.id = config.id;
    this.labelI18nKey = config.labelI18nKey;
    this.labelInListI18nKey = config.labelInListI18nKey;
    this.disableTextTransform = config.disableTextTransform;
  }

  isAvailableToUser(userPermissions: UserPermissions) {
    if (!this.config.contentTypes) {
      return true;
    }
    const permissions = this.config.contentTypes.map((type) => getPermissionsByContentType(type).access);
    return !permissions.some((filterPermission) => !userPermissions[filterPermission]);
  }

  get contentTypeForBackend() {
    return this.config.contentTypes?.join();
  }

  get isPro() {
    return !!this.config.contentTypes?.some(isContentPro);
  }
}

class FavoritesFiltersSet {
  private readonly filters: FavoritesFilter[];
  private readonly filtersById: Record<string, FavoritesFilter>;
  defaultFilter: FavoritesFilter;

  constructor(filters: FavoritesFilter[]) {
    this.defaultFilter = filters[0];
    this.filters = filters;
    this.filtersById = filters.reduce<Record<string, FavoritesFilter>>((obj, filter) => {
      return {
        ...obj,
        [filter.id]: filter,
      };
    }, {});
  }

  getAvailableFilters(userPermissions: UserPermissions) {
    return this.filters.filter((item) => item.isAvailableToUser(userPermissions));
  }

  getFilterById(filterId: string) {
    return this.filtersById[filterId];
  }
}

const AllFilter = new FavoritesFilter({
  id: 'all',
  labelI18nKey: 'favorites:all',
});

const DrugsFilter = new FavoritesFilter({
  id: 'filter-monograph',
  contentTypes: [ContentType.Drug],
  labelI18nKey: 'favorites:drugs',
});

const DxTxFilter = new FavoritesFilter({
  id: 'filter-dx-tx',
  contentTypes: clinicalBriefsContentTypes,
  labelI18nKey: 'favorites:dxTx',
  labelInListI18nKey: 'favorites:clinicalBriefsDropdown',
  disableTextTransform: true,
});

const DDxFilter = new FavoritesFilter({
  id: 'filter-ddx',
  contentTypes: [ContentType.DDx],
  labelI18nKey: 'favorites:ddx',
  labelInListI18nKey: 'favorites:ddxDropdown',
  disableTextTransform: true,
});

const AppendixFilter = new FavoritesFilter({
  id: 'filter-appendix',
  contentTypes: [ContentType.Appendix],
  labelI18nKey: 'favorites:appendix',
});

const AlgorithmsFilter = new FavoritesFilter({
  id: 'filter-algorithm',
  contentTypes: [ContentType.Algorithm],
  labelI18nKey: 'favorites:algorithms',
});

const DrugHandoutsFilter = new FavoritesFilter({
  id: 'filter-drug-handout',
  contentTypes: drugHandoutContentTypes,
  labelI18nKey: 'favorites:drugHandouts',
});

const ClinicalHandoutsFilter = new FavoritesFilter({
  id: 'filter-clinical-handout',
  contentTypes: clinicalHandoutsContentTypes,
  labelI18nKey: 'favorites:clinicalHandouts',
});

export const FavoritesFilters = new FavoritesFiltersSet([
  AllFilter,
  DxTxFilter,
  DDxFilter,
  DrugsFilter,
  AlgorithmsFilter,
  ClinicalHandoutsFilter,
  DrugHandoutsFilter,
  AppendixFilter,
]);
