import { useCallback, useEffect, useMemo, useRef, RefObject } from 'react';
import { FlatList, StyleSheet } from 'react-native';

import { NEW_LIST_PARAM, UPDATED_LIST_PARAM } from 'src/constants/content';
import {
  ContentInList,
  ContentListItemCommonProps,
  ContentListType,
  SpecialAccordionLocation,
} from 'src/constants/types';
import { AccordionItemParams, AccordionRef } from 'src/constants/types/accordion';
import { useUserInfo } from 'src/features/auth/hooks';
import { isWeb } from 'src/helpers';
import { useOpenAccordionAction, useContentListByContentType } from 'src/hooks/queries/contentLists';
import { useContentListAccordionEvents } from 'src/hooks/useContentListAccordionEvents';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { useNavigation } from 'src/navigation/hooks/useNavigation';

import { AccordionLegacy } from './AccordionLegacy';
import { InfiniteScrollList } from './InfiniteScrollList';
import { InfiniteScrollListLegacy } from './InfiniteScrollListLegacy';

interface Props<CT extends ContentListType> {
  contentListType: CT;
  ContentCardComponent: React.FC<ContentListItemCommonProps<ContentInList[CT]>>;
  accordionRef: RefObject<AccordionRef>;
}

type AccordionItem = 'favorites' | 'new' | 'updated';

export const SpecialContentListAccordions = <CT extends ContentListType>({
  contentListType,
  ContentCardComponent,
  accordionRef,
}: Props<CT>) => {
  const scrollRef = useRef<FlatList>(null);
  const { isTablet } = useDeviceInfo();
  const navigation = useNavigation();
  const { isSharedAccount } = useUserInfo();
  const { onItemClose, onItemOpen } = useContentListAccordionEvents(contentListType);
  const { mutate: markAccordionAsSeen } = useOpenAccordionAction(contentListType);

  const favorites = useContentListByContentType(
    contentListType,
    { isFavorite: true },
    { refetchOnMount: true },
  );

  const newList = useContentListByContentType(contentListType, NEW_LIST_PARAM);
  const updatedList = useContentListByContentType(contentListType, UPDATED_LIST_PARAM);

  const accordionItems = useMemo(() => {
    const ListComponent = isWeb ? InfiniteScrollList : InfiniteScrollListLegacy;

    const getContent = (
      { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage }: typeof favorites,
      location: SpecialAccordionLocation,
    ) => (
      <ListComponent
        data={data.items}
        loading={isLoading || isFetchingNextPage}
        allFetched={!hasNextPage}
        fetchNextPage={fetchNextPage}
        renderItem={({ item, index }) => (
          <ContentCardComponent
            item={item}
            location={location}
            bottomBorder={false}
            topBorder={index !== 0}
          />
        )}
        keyExtractor={(item) => item.id}
      />
    );

    const items: AccordionItemParams<AccordionItem>[] = [];
    if (favorites.data.count > 0) {
      items.push({
        Label: i18n.t('favorites:favorites'),
        Content: getContent(favorites, 'content-list-favorites'),
        id: 'favorites',
      });
    }

    if (newList.data.count > 0) {
      items.push({
        Label: i18n.t('contents:new'),
        Content: getContent(newList, 'content-list-new'),
        id: 'new',
        numberIndicator: newList.data.unseenCount,
      });
    }

    if (updatedList.data.count > 0) {
      items.push({
        Label: i18n.t('contents:updated'),
        Content: getContent(updatedList, 'content-list-updated'),
        id: 'updated',
        numberIndicator: updatedList.data.unseenCount,
      });
    }

    return items;
  }, [favorites, newList, updatedList, ContentCardComponent]);

  const refetchStaleLists = useCallback(() => {
    [newList, updatedList].forEach((list) => {
      // we use here additional check because we don't always get isStale flag (true) on native
      // never on iOS and sometimes on Android
      if (list.isStale || list.data.unseenCount) {
        list.refetch();
      }
    });
  }, [newList, updatedList]);

  useEffect(() => {
    if (!isWeb) {
      /**
       * Refreshing stale lists when screen is focused
       * Couldn't be done with refetchOnMount because component is always mounted when user switch between screens
       */
      const unsubscribe = navigation.addListener('focus', refetchStaleLists);

      return unsubscribe;
    }
  }, [navigation, refetchStaleLists]);

  if (accordionItems.length === 0) {
    return null;
  }

  const handleItemOpen = (id: AccordionItem) => {
    onItemOpen(getItemNameForEvent(id));
    if (!isSharedAccount && (id === 'new' || id === 'updated')) {
      markAccordionAsSeen(id === 'new' ? 'New' : 'Updated');
    }
  };

  const handleItemClose = (id: AccordionItem) => {
    onItemClose(getItemNameForEvent(id));
  };

  return (
    <AccordionLegacy
      testID="special-accordion-sections"
      ref={accordionRef}
      listRef={scrollRef}
      contentContainerStyle={styles.accordion}
      fullWidthMode={!isTablet}
      items={accordionItems}
      onItemOpen={handleItemOpen}
      onItemClose={handleItemClose}
    />
  );
};

const getItemNameForEvent = (listId: AccordionItem): string => {
  switch (listId) {
    case 'favorites':
      return 'Favorites';
    case 'new':
      return 'New';
    case 'updated':
      return 'Updated';
  }
};

const styles = StyleSheet.create({
  accordion: {
    marginBottom: 28,
  },
});
