import { debounce } from 'debounce';
import { useEffect, useMemo, useState } from 'react';

import { QueryErrorBanner } from 'src/errorHandling/components';
import { isWeb } from 'src/helpers';

import { SearchResultsList } from './SearchResultsList';
import { useSearch, useSearchParams } from '../hooks';
import type { SearchQueryParams } from '../types';

interface Props {}

export const SearchResults: React.FC<Props> = () => {
  const { query, contentTypes } = useSearchParams();
  const [params, setParams] = useState<SearchQueryParams>({
    query: '',
    contentTypes,
  });
  const { data, fetchNextPage, isError, isFetching, error, fetchStatus } = useSearch(params);

  const updateQuery = useMemo(
    () => debounce((query: string) => setParams((params) => ({ ...params, query })), 500),
    [],
  );

  useEffect(() => {
    setParams((params) => ({ ...params, contentTypes }));
  }, [contentTypes]);

  useEffect(() => {
    if (isWeb) {
      window.scrollTo({ top: 0 });
    }
    updateQuery(query);
  }, [query, updateQuery]);

  return (
    <>
      <QueryErrorBanner isDataAvailable={!!data} error={error} fetchStatus={fetchStatus} />
      <SearchResultsList
        searchNextPage={fetchNextPage}
        data={data?.items ?? []}
        count={data?.count ?? 0}
        isError={isError}
        isLoading={isFetching}
        page={data?.page}
      />
    </>
  );
};
