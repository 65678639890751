export const NAVBAR_HEIGHT = 80;
export const SEARCH_BOX_HEIGHT = 80;
export const SEARCH_BOX_EXPANDED_HEIGHT = 240;
export const MOBILE_TOC_HEIGHT = 56;
export const FILTER_BAR_HEIGHT = 48;
export const TABS_BAR_HEIGHT = 56;
export const COLLAPSED_DRAWER_BAR_HEIGHT = 56;
export const CONTENT_TITLE_BAR_HEIGHT = 50;

export const BRANDS_PANEL_HEIGHT = 40;
export const TOP_NAV_PANEL_HEIGHT = 80;
export const SEARCH_BAR_HEIGHT = 62;
export const BOTTOM_BAR_HEIGHT = 48;

export const SIDEBAR_WIDTH = 375;

export const CONTAINER_WIDTH_MOBILE = '92%';
export const CONTAINER_WIDTH_TABLET = '95%';
export const CONTAINER_WIDTH_TABLET_APP = 600;
export const CONTAINER_MAX_WIDTH = {
  wide: 1080,
  narrow: 750,
} as const;

export const CONTAINER_PADDING_H_MOBILE = 16;
export const CONTAINER_PADDING_V_MOBILE = 28;
export const CONTAINER_PADDING_V_MOBILE_APP = 25;
export const CONTAINER_PADDING_V_TABLET = 48;
export const CONTAINER_PADDING_V_DESKTOP_LARGE = 60;

export const CARDS_LIST_DESKTOP_WIDTH = 620;
export const DESKTOP_CONTENT_WIDTH = 750;

export const SEARCH_BAR_TRANSITION_TIME = '0.75s';
export const BOTTOM_TAB_NAVIGATION_HEIGHT = 70; // TODO: remove
export const FAB_PADDING_BOTTOM_RIGHT = 16;
export const HEADER_HEIGHT = 60;

export const TH_WIDTH_HORIZONTAL = 100;
export const TD_WIDTH_HORIZONTAL = 200;

export const TOC_DESKTOP_WIDTH = 300;
export const TOC_DESKTOP_PADDING = 28;

export const FLASH_LIST_ESTIMATED_ITEM_SIZE = 48;
