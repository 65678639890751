import React from 'react';

import { ContentListItem } from 'src/components';
import { ContentListItemCommonProps } from 'src/constants/types';

import type { DDx, DDxDetails } from '../types';

type Props = ContentListItemCommonProps<DDx | DDxDetails>;

export const DDxCard: React.FC<Props> = React.memo<Props>(({ item, ...rest }) => {
  return <ContentListItem data={item} {...rest} />;
});
