import dayjs from 'dayjs';

import { isPersonalInformationSectionFilled } from 'src/features/profile/helpers';
import { StudentVerificationParams } from 'src/features/studentVerification/types';

import { getBasePlanSetup } from '../helpers';
import { Role } from '../types';
import { RegistrationFormStep, type RegistrationStepsData } from './types';

export const getProfileDataFromStudentVerification = (
  data: StudentVerificationParams,
): RegistrationStepsData[RegistrationFormStep.CompleteYourProfile] => {
  const { firstName, country, lastName, organizationName, birthDate, planCode } = data;
  const { role } = getBasePlanSetup(planCode);
  const year = dayjs(birthDate).get('year');

  return {
    information: {
      firstName,
      lastName,
      country,
      bornYear: year.toString(),
      address1: '',
      address2: '',
      city: '',
      phone: '',
      postalCode: '',
      practice: '',
      state: '',
    },
    occupation: {
      activity: role === Role.PHARMACY_STUDENT ? 'student-pharmacy' : 'student-veterinary-medical',
      schoolAttending: 'other',
      schoolAttendingOther: organizationName,
      graduationYear: null,
      activityOther: null,
      numberOfVeterinarians: null,
      pharmacy: null,
      pharmacyOther: null,
      practiceOwnership: null,
      practiceType: null,
      practiceTypeOther: null,
      role: null,
      roleOther: null,
      schoolGraduated: null,
      schoolGraduatedOther: null,
      yearsOfPractice: null,
    },
  };
};

export const isCreateAccountDataFilled = (
  data: RegistrationStepsData[RegistrationFormStep.CreateAnAccount] | null,
) => !!data?.email && !!data?.confirmEmail && !!data?.password && !!data?.confirmPassword;

export const isCompleteYourProfileDataFilled = (
  data: RegistrationStepsData[RegistrationFormStep.CompleteYourProfile] | null,
) => isPersonalInformationSectionFilled(data?.information) && !!data?.occupation;
