import React, { useCallback, useRef, useState, CSSProperties } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';
import { SwiperFlatList } from 'react-native-swiper-flatlist';

import { BaseModal, IconButton, LoadingIndicator, StyledText } from 'src/components';
import { handleImageRightClick } from 'src/helpers/functions';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

import { DxTxImageDescription } from './DxTxImageDescription';
import { useDxTxImage } from '../hooks';

interface Props {
  id: string;
  close: () => void;
}

export const DxTxImageModal: React.FC<Props> = ({ id, close }) => {
  const { data, isLoading } = useDxTxImage(id);
  const images = data?.imagesUrls ?? [];
  const title = data?.figureTitle ?? '';
  const altText = data?.altText ?? '';
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [currentImgIdx, setCurrentImgIdx] = useState(0);
  const swiperRef = useRef<SwiperFlatList>(null);
  const { isTablet } = useDeviceInfo();

  const wrapperOnLayoutHandler = useCallback((e: LayoutChangeEvent) => {
    setWrapperWidth(e.nativeEvent.layout.width);
  }, []);

  const controls = images.length > 1 && (
    <View style={styles.controlsWrapper}>
      {isTablet && (
        <IconButton
          onPress={() => swiperRef?.current?.scrollToIndex({ index: (currentImgIdx || images.length) - 1 })}
          name="chevron-left"
          width={16}
          color={palette.blue}
          style={styles.controlButton}
        />
      )}
      <StyledText
        style={[
          typography.body2,
          styles.multipleImagesIndicator,
          !isTablet && styles.multipleImagesIndicatorMobile,
        ]}
      >
        {i18n.t(`dxTx:nthOf`, { nth: currentImgIdx + 1, count: images.length })}
      </StyledText>
      {isTablet && (
        <IconButton
          onPress={() =>
            swiperRef?.current?.scrollToIndex({
              index: currentImgIdx + 1 < images.length ? currentImgIdx + 1 : 0,
            })
          }
          name="chevron-right"
          width={16}
          color={palette.blue}
          style={styles.controlButton}
        />
      )}
    </View>
  );

  return (
    <BaseModal
      scroll={false}
      close={close}
      header={data && <StyledText style={[styles.title, typography.body5Bold]}>{title}</StyledText>}
      footer={controls}
    >
      {(isLoading && <LoadingIndicator style={styles.loader} />) ||
        (data && (
          <View onLayout={wrapperOnLayoutHandler} style={styles.webHeight}>
            <DxTxImageDescription description={data.figureLegend} />
            <SwiperFlatList
              style={styles.webHeight}
              ref={swiperRef}
              index={0}
              // seems to fix issue with scrolling to other than first image at start
              data={wrapperWidth ? images : [images[0]]}
              renderAll
              renderItem={({ item }) => (
                <View style={[styles.imageWrapper, { width: wrapperWidth }]}>
                  <img
                    src={item}
                    alt={altText}
                    style={cssStyles.image}
                    onContextMenu={handleImageRightClick}
                  />
                </View>
              )}
              onChangeIndex={({ index }) => {
                setCurrentImgIdx(index);
              }}
              showPagination={!isTablet && images.length > 1}
              paginationStyle={styles.pagination}
              paginationStyleItem={styles.paginationItem}
              paginationDefaultColor={palette.brightBlue3}
              paginationActiveColor={palette.blue}
              getItemLayout={(_, index) => ({
                length: wrapperWidth,
                offset: wrapperWidth * index,
                index,
              })}
            />
          </View>
        ))}
    </BaseModal>
  );
};

const cssStyles: Record<string, CSSProperties> = {
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
};

const styles = StyleSheet.create({
  title: {
    color: palette.navy,
    marginBottom: 16,
  },
  loader: {
    marginVertical: 40,
  },
  pagination: {
    position: 'relative',
    marginTop: 17,
    marginBottom: 0,
    height: 10,
  },
  paginationItem: {
    height: 10,
    width: 10,
    marginHorizontal: 2,
  },
  controlsWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  controlButton: {
    marginTop: 6,
    marginHorizontal: 16,
  },
  multipleImagesIndicator: {
    marginTop: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  multipleImagesIndicatorMobile: {
    position: 'absolute',
    right: 0,
    bottom: -10,
  },
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  webHeight: {
    height: '100%',
  },
});
