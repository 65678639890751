import type { ProfileInformation } from 'src/features/profile';
import { Nullable } from 'src/helpers';

import type { BillingInformation, CreateAccount, PlanSetup, Purchase } from '../types';

/** Steps that can be used inside a Registration Form */
export enum RegistrationFormStep {
  CreateAnAccount = 'CreateAnAccount',
  CompleteYourProfile = 'CompleteYourProfile',
  ReviewPlanDetails = 'ReviewPlanDetails',
  PaymentDetails = 'PaymentDetails',
}

/** Data handled by each Registration Form Step */
export interface RegistrationStepsData extends Record<RegistrationFormStep, Record<string, any>> {
  [RegistrationFormStep.CreateAnAccount]: CreateAccount;
  [RegistrationFormStep.CompleteYourProfile]: ProfileInformation;
  [RegistrationFormStep.ReviewPlanDetails]: {
    planSetup: PlanSetup | null;
    purchasePreview: Purchase | null;
  };
  [RegistrationFormStep.PaymentDetails]: BillingInformation;
}

/** Events emitted by a Registration Form context */
export type RegistrationFormEvent = {
  'step-error': {
    step: RegistrationFormStep;
    errors: Record<string, string>;
  };
  'form-submitted': undefined;
  'step-submitted': {
    step: RegistrationFormStep;
  };
};

/** Each Registration Form context needs to extends this interface */
export interface RegistrationFormContext {
  /** Returns data submitted by given Step. Can return `null` if Step hasn't been submitted yet */
  getStepData<T extends RegistrationFormStep>(step: T): RegistrationStepsData[T] | null;
  /** Submits a Step. Data needs to be already validated; won't be checked inside a contex */
  setStepData<T extends RegistrationFormStep>(step: T, data: RegistrationStepsData[T]): void;
  /** Returns a Step following a given Step or `null` if given Step is the last one */
  getNextStep(step: RegistrationFormStep): RegistrationFormStep | null;
  onDirtyChange(step: RegistrationFormStep, isDirty: boolean): void;
  /** Indicates if given Step is active, which means that all preceding steps have been submitted */
  isStepActive(step: RegistrationFormStep): boolean;
  /** Indicates if given Step has been already submitted */
  isStepSubmitted(step: RegistrationFormStep): boolean;
  /** Submits the form */
  submit(): Promise<void>;
  /** Allows subscribing to event emitter which sends events described with RegistrationFormEvent */
  on<T extends keyof RegistrationFormEvent>(
    type: T,
    callback: (payload: RegistrationFormEvent[T]) => void,
  ): () => void;
  /** Indicates if any Step has unsaved changes */
  isDirty: boolean;
  /** Indicates if all the required Steps has been filled */
  isFilled: boolean;
  isSubmitting: boolean;
  formData: Nullable<RegistrationStepsData>;
  email: string;
}
