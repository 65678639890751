import React from 'react';

import { ContentListItem } from 'src/components';
import { ContentListItemCommonProps } from 'src/constants/types';

import type { Algorithm } from '../types';

type Props = ContentListItemCommonProps<Algorithm>;

export const AlgorithmCard = React.memo<Props>(({ item, ...rest }) => {
  return <ContentListItem data={item} {...rest} />;
});
