import React, { useCallback, useState } from 'react';
import { StyleSheet, StyleProp, ViewStyle, ScrollView, View } from 'react-native';

import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import { NavigationMenuItem } from 'src/navigation/types';
import { getShadow, ifWeb, palette } from 'src/styles';

import { NavigationDropdownItem } from './NavigationDropdownItem';

interface Props {
  additionalContent?: React.ReactNode;
  items: NavigationMenuItem[];
  containerStyles?: StyleProp<ViewStyle>;
  isDisplayed?: boolean;
  close?: () => void;
}

export const NavigationDropdown: React.FC<Props> = ({
  items,
  containerStyles,
  close,
  isDisplayed,
  additionalContent,
}) => {
  const { topPanelHeight } = useWebAppLayout();
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);

  const toggleSubmenu = useCallback((key: string) => {
    setActiveSubmenu((state) => (state === key ? null : key));
  }, []);

  const maxHeight = `calc(100vh - ${topPanelHeight}px)`;

  return (
    <View style={[styles.container, isDisplayed && { maxHeight }, containerStyles]}>
      <ScrollView style={[styles.scrollWrapper, { maxHeight }]}>
        {additionalContent}
        {items.map((item, index) => {
          return (
            <NavigationDropdownItem
              {...item}
              isSubmenuOpen={activeSubmenu === item.key}
              closeDropdown={close}
              toggleSubmenu={() => toggleSubmenu(item.key)}
              key={item.key}
              isLastItem={index === items.length - 1}
            />
          );
        })}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    opacity: 1,
    position: 'absolute',
    width: '206px',
    top: '100%',
    maxHeight: 0,
    backgroundColor: palette.white,
    ...getShadow(4, 0.5),
    ...ifWeb({
      overflowY: 'hidden',
      transition: 'max-height 250ms',
    }),
  },
  scrollWrapper: {
    flexShrink: 0,
  },
});
