import { useMutation, useQuery, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { queryClient } from 'src/api/queryClient';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import { LICENCES_INFO_QUERY_KEY } from 'src/features/adminDashboard/constants';
import { useIsSignedIn, useUserInfo } from 'src/features/auth/hooks';
import { refreshAccessToken } from 'src/features/auth/state';
import {
  parseOccupationDetailsForBackend,
  parsePersonalInformationForBackend,
} from 'src/features/profile/helpers';
import { type PersonalInformationPayload } from 'src/features/profile/types';
import {
  MixpanelEvent,
  MixpanelService,
  SentryService,
  getMixpanelUpgradeToProLocation,
} from 'src/features/tracking';
import { showNotification } from 'src/helpers/notifications';
import { clearRedirectTo } from 'src/navigation/utils';
import { ApplicationState } from 'src/state';
import { AppDispatch } from 'src/state/store';

import {
  applyPromotionForExistingUser,
  cancelSubscription,
  changePlan,
  fetchBillingInformation,
  fetchInvoiceData,
  fetchInvoicesList,
  fetchSubscriptionInfo,
  getPromotionSetup,
  purchaseSubscription,
  purchaseSubscriptionAsExistingUser,
  renewSubscription,
  updateBillingInformation,
  verifySubscriptionInvitation,
} from '../../api';
import {
  BILLING_INFO_QUERY_KEY,
  INVOICES_QUERY_KEY,
  PROMOTION_QUERY_KEY,
  SUBSCRIPTION_INFO_QUERY_KEY,
} from '../../constants';
import {
  getRecurlyPlan,
  isBillingInformation,
  isPromotionForExistingSubscribersOnlyError,
  isPromotionForNewSubscribersOnlyError,
  isStudentRole,
  parseSubscriptionDataFromBackend,
  trackPurchaseGAEvent,
} from '../../helpers';
import { clearPromotionParams, setPromotionType } from '../../state';
import {
  ChangePlanPayload,
  type CreateAccount,
  type InvoicesListFetchParams,
  type PlanSetup,
  type PromotionLandingScreenParams,
  type PurchaseSubscriptionQueryParams,
  PurchaseSubscriptionAsExistingUserPayload,
  ApplyPromotionParams,
  PlanUpdateParams,
} from '../../types';

type UseInvoicesListOptions = {
  params: InvoicesListFetchParams;
  enabled?: boolean;
};

export const useInvoicesList = ({ params, enabled }: UseInvoicesListOptions) =>
  useQuery({
    queryKey: [INVOICES_QUERY_KEY, params],
    queryFn: () => fetchInvoicesList(params),
    enabled,
    placeholderData: keepPreviousData,
    select: ({ results, count, next }) => ({
      results,
      count,
      numberOfPages: Math.ceil(count / params.pageSize),
      isLastPage: !next,
    }),
  });

export const useInvoiceData = (invoiceId: string) => {
  const queryResult = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: () => fetchInvoiceData(invoiceId),
    enabled: false,
  });

  useEffect(() => {
    if (queryResult.isError) {
      showNotification({ type: 'error' });
    }
  }, [queryResult.isError]);

  return queryResult;
};

export const usePromotion = (_params?: PromotionLandingScreenParams) => {
  const dispatch = useDispatch();
  const isSignedIn = useIsSignedIn();
  const storeParams = useSelector((state: ApplicationState) => state.promotion.params);
  const { data: subscriptionData } = useSubscriptionInfo();

  const currentPromotion = subscriptionData?.promotion;

  const params = _params || storeParams;

  const queryResult = useQuery(
    params?.promoId && params?.userID
      ? {
          queryKey: [PROMOTION_QUERY_KEY, params.promoId, params.userID, isSignedIn],
          queryFn: () => getPromotionSetup(params.promoId, params.userID, isSignedIn),
          retryOnMount: false,
          select: (data) => ({
            ...data!,
            params,
          }),
        }
      : {
          /**
           * User didn't open the promotion form via /promotion URL - we return the promo applied to their subscription if it's available.
           * Used when promo participant with Basic plan want to use the promo again to upgrade to Pro
           */
          queryKey: [PROMOTION_QUERY_KEY, currentPromotion?.id],
          queryFn: () => currentPromotion,
          enabled: !!currentPromotion,
        },
  );

  useEffect(() => {
    const error = queryResult.error;
    if (error) {
      const detailError = error?.response?.data?.detail;

      const forNewOnly = isPromotionForNewSubscribersOnlyError(error);
      const forExistingOnly = isPromotionForExistingSubscribersOnlyError(error);

      if (forNewOnly) {
        dispatch(setPromotionType('new'));
      } else if (forExistingOnly) {
        dispatch(setPromotionType('existing'));
      } else {
        // if error is returned because promotion is only for new/ existing users, then we keep the promotion param saved
        // to fetch it again after user sign in/ sign out. In other cases, we remove the params
        dispatch(clearPromotionParams());
        clearRedirectTo();
      }

      if (detailError) {
        showNotification({
          type: 'error',
          title: getErrorMessageForApiError(detailError, 'promotion'),
          autoHide: false,
        });
      }
    }
  }, [queryResult.error, dispatch]);

  return {
    ...queryResult,
    params,
  };
};

export const useApplyPromotion = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    tokens: { refresh },
  } = useSelector((state: ApplicationState) => state.auth);

  return useMutation({
    mutationFn: (payload: { data: ApplyPromotionParams; updateParams: PlanUpdateParams }) =>
      applyPromotionForExistingUser(payload.data),
    onSuccess: async (_data, payload) => {
      showNotification({ type: 'success' });
      await dispatch(refreshAccessToken(refresh));
      onPlanUpdate(payload.updateParams);
      dispatch(clearPromotionParams());
      clearRedirectTo();
    },
  });
};

export const useBillingInformation = () => {
  const { hasRecurlyAccount } = useUserInfo();

  const queryData = useQuery({
    queryKey: [BILLING_INFO_QUERY_KEY],
    queryFn: async () => {
      const data = await fetchBillingInformation();
      return isBillingInformation(data) ? data : null;
    },
    enabled: hasRecurlyAccount,
  });

  return queryData;
};

export const useUpdateBillingInformation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateBillingInformation,
    onSuccess: (response) => {
      queryClient.setQueryData([BILLING_INFO_QUERY_KEY], response.data);
    },
  });
};

export const useCancelSubscriptionRenewal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: cancelSubscription,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [SUBSCRIPTION_INFO_QUERY_KEY] });
    },
    onError: () => {
      showNotification({ type: 'error' });
    },
  });
};

export const useRenewSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: renewSubscription,
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: [SUBSCRIPTION_INFO_QUERY_KEY] });
    },
    onError: () => {
      showNotification({ type: 'error' });
    },
  });
};

export const usePurchaseSubscription = () => {
  return useMutation({
    mutationFn: async (params: PurchaseSubscriptionQueryParams) => {
      const {
        subscriptionData,
        billingInfoToken,
        captchaToken,
        isTrial,
        promotionParams,
        studentVerificationId,
        subscriptionInvitationId,
      } = params;
      const personalInformation = parsePersonalInformationForBackend(
        subscriptionData.completeYourProfile.information,
      ) as PersonalInformationPayload;
      const { email, password } = subscriptionData.createAccount as CreateAccount;
      const { discountCode, quantity, plan, role, billingPeriod } = subscriptionData.planSetup as PlanSetup;
      const planCode = getRecurlyPlan(role, quantity, plan, billingPeriod);
      return purchaseSubscription({
        ...personalInformation,
        email,
        password,
        planCode,
        billingInfoTokenId: billingInfoToken.id,
        discountCode: discountCode ? discountCode : undefined,
        // dirty hack to omit backend validation for quantiry higher than normally allowed
        quantity: subscriptionInvitationId ? 2 : quantity,
        demographics: {
          ...personalInformation.demographics,
          ...parseOccupationDetailsForBackend(subscriptionData.completeYourProfile!.occupation),
        },
        isTrial,
        captchaToken,
        subscriptionInvitationId,
        promotionId: promotionParams?.promoId,
        userIdentifier: promotionParams?.userID,
        hospitalName: promotionParams?.hospital,
        verificationId: isStudentRole(role) ? studentVerificationId : undefined,
      });
    },
    onSuccess: (responseData, params) => {
      const { subscriptionData } = params;
      const { id: userId, invoiceNumber } = responseData;
      MixpanelService.identify(userId);
      MixpanelService.track(MixpanelEvent.Subscribe);
      MixpanelService.clearUser();
      trackPurchaseGAEvent(subscriptionData, invoiceNumber);
    },
    onError: (error: any, params) => {
      SentryService.captureMessage('Subscription purchase failed', {
        data: params.subscriptionData,
        error: error,
        errorResponse: error?.response,
      });
    },
  });
};

export const usePurchaseSubscriptionAsExistingUser = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    tokens: { refresh },
  } = useSelector((state: ApplicationState) => state.auth);

  return useMutation({
    mutationFn: (payload: {
      data: PurchaseSubscriptionAsExistingUserPayload;
      updateParams: PlanUpdateParams;
    }) => purchaseSubscriptionAsExistingUser(payload.data),
    onSuccess: async (_data, payload) => {
      showNotification({ type: 'success' });
      await dispatch(refreshAccessToken(refresh));
      onPlanUpdate(payload.updateParams);
    },
  });
};

export const useChangePlan = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    tokens: { refresh },
  } = useSelector((state: ApplicationState) => state.auth);

  return useMutation({
    mutationFn: (payload: { data: ChangePlanPayload; updateParams: PlanUpdateParams }) =>
      changePlan(payload.data),
    onSuccess: async (_data, payload) => {
      showNotification({ type: 'success' });
      await dispatch(refreshAccessToken(refresh));
      onPlanUpdate(payload.updateParams);
    },
  });
};

export const useSubscriptionInfo = (options: { refetchOnMount?: 'always' } = {}) => {
  const { hasRecurlyAccount } = useUserInfo();

  return useQuery({
    queryFn: async () => {
      const data = await fetchSubscriptionInfo();
      return parseSubscriptionDataFromBackend(data);
    },
    queryKey: [SUBSCRIPTION_INFO_QUERY_KEY],
    enabled: hasRecurlyAccount,
    ...options,
  });
};

export const useSubscriptionInvitationVerification = (invitationId: string) => {
  const { t } = useTranslation('subscriptionProcess');

  const queryResult = useQuery({
    queryKey: ['subsription-invitation-verification', invitationId],
    queryFn: () => verifySubscriptionInvitation(invitationId),
  });

  useEffect(() => {
    if (queryResult.isError) {
      showNotification({ type: 'error', title: t('subscriptionInvitationInvalid') });
    }
  }, [queryResult.isError, t]);

  return queryResult;
};

const onPlanUpdate = (params: PlanUpdateParams) => {
  queryClient.refetchQueries({ queryKey: [SUBSCRIPTION_INFO_QUERY_KEY] });
  if (params.isUpgradingToPro) {
    // Backend might send different results to PRO and NORMAL users
    queryClient.invalidateQueries();

    MixpanelService.track(MixpanelEvent.UpgradeToPro, {
      Location: getMixpanelUpgradeToProLocation(params.source),
    });
  } else {
    queryClient.invalidateQueries({ queryKey: [INVOICES_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [LICENCES_INFO_QUERY_KEY] });
  }

  if (params.isIncreasingNumberOfLicences) {
    MixpanelService.track(MixpanelEvent.AddMoreUsers, {
      Location: 'Subscription Details Page',
    });
  }
};
