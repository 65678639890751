import { Reducer, useReducer } from 'react';

import { Nullable } from 'src/helpers';

import { RegistrationFormStep, RegistrationStepsData } from './types';

export interface RegistrationFormState {
  data: Nullable<RegistrationStepsData>;
  dirtySteps: RegistrationFormStep[];
}

type SubmitStepAction<T extends RegistrationFormStep> = {
  type: 'submit-step';
  step: T;
  data: RegistrationStepsData[T];
  overwrite?: boolean;
};

export type RegistrationFormAction =
  | SubmitStepAction<RegistrationFormStep>
  | { type: 'set-dirty-step'; step: RegistrationFormStep; isDirty: boolean };

export const reducer: Reducer<RegistrationFormState, RegistrationFormAction> = (state, action) => {
  switch (action.type) {
    case 'submit-step': {
      const { step, data, overwrite = true } = action;

      if (!overwrite && !!state.data[step]) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          [step]: data,
        },
      };
    }
    case 'set-dirty-step': {
      let dirtySteps = [];
      if (action.isDirty) {
        dirtySteps = Array.from(new Set([...state.dirtySteps, action.step]));
      } else {
        dirtySteps = state.dirtySteps.filter((item) => item !== action.step);
      }
      return {
        ...state,
        dirtySteps,
      };
    }
  }
};

/** Reducer to be used in any Registration Form.
 * Keeps track of data submitted by each RegistrationFormStep and if there are any unsaved changes in them. */
export const useRegistrationFormReducer = <I>(
  initializerArg: I,
  initializer: (arg: I) => RegistrationFormState,
) => {
  return useReducer(reducer, initializerArg, initializer);
};
