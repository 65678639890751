import React from 'react';
import { StyleSheet } from 'react-native';

import { isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';

import { EmailChange } from './EmailChange/EmailChange';
import { GroupInfoBox } from './GroupInfoBox';
import { AccountInformationSection as Section } from '../AccountInformationSection';
import { PasswordChange } from './PasswordChange/PasswordChange';

export const AccountDetails: React.FC = () => {
  const { isTablet } = useDeviceInfo();

  const content = (
    <>
      <GroupInfoBox />
      <Section.Form>
        <Section.Row style={!isTablet && styles.spacing}>
          <EmailChange />
        </Section.Row>
        <Section.Row>
          <PasswordChange />
        </Section.Row>
      </Section.Form>
    </>
  );
  return isWeb ? (
    <Section title={i18n.t('accountInformation:accountDetails')} testID="setion-account-details">
      {content}
    </Section>
  ) : (
    content
  );
};

const styles = StyleSheet.create({
  spacing: {
    marginBottom: 20,
  },
});
