import { createContext, useContext } from 'react';
import type { TokenPayload } from 'recurly__recurly-js';

import { useUserInfo } from 'src/features/auth/hooks/useUserInfo';
import { prepareOccupationDetailsData, preparePersonalInformationData } from 'src/features/profile/helpers';
import { usePersonalInformation } from 'src/features/profile/hooks/queries';
import { EventEmitterService } from 'src/services';

import { useConvertManagedGroup } from '../hooks/queries/';
import { PlanSetup } from '../types';
import { RegistrationFormState, useRegistrationFormReducer } from './state';
import {
  RegistrationFormContext,
  RegistrationFormEvent,
  RegistrationFormStep as Step,
  RegistrationStepsData as StepsData,
} from './types';

interface IGroupConversionFormContext extends RegistrationFormContext {}

interface Props extends React.PropsWithChildren {
  id: string;
  planSetup: PlanSetup;
  getRecurlyTokenPayload(): Promise<TokenPayload>;
}

const Emitter = new EventEmitterService<RegistrationFormEvent>();
const UNHANDLED_STEP_MESSAGE = 'Provided Step is not handled in this Registration Form';
const STEPS = Object.freeze([Step.ReviewPlanDetails, Step.PaymentDetails]);

export const GroupConversionFormContext = createContext<IGroupConversionFormContext | null>(null);

/** Contains logic for form to convert a Managed Multi-User Plan to a Self Serve Group. Built upon RegistrationFormContext interface */
export const GroupConversionFormContextProvider: React.FC<Props> = ({
  children,
  planSetup,
  id,
  getRecurlyTokenPayload,
}) => {
  const { email } = useUserInfo();
  const { data: personalInformation } = usePersonalInformation();
  const [state, dispatch] = useRegistrationFormReducer(
    { email, personalInformation: preparePersonalInformationData(personalInformation), planSetup },
    getInitialState,
  );
  const { mutate: convertGroup, isPending: isSubmitting } = useConvertManagedGroup();

  const getStepData = <T extends Step>(step: T): StepsData[T] | null => {
    if (!STEPS.includes(step)) {
      throw new Error(UNHANDLED_STEP_MESSAGE);
    }
    return state.data[step];
  };

  const isStepSubmitted: IGroupConversionFormContext['isStepSubmitted'] = (step) => {
    switch (step) {
      case Step.ReviewPlanDetails:
        return (
          !!state.data[Step.ReviewPlanDetails]?.planSetup &&
          !!state.data[Step.ReviewPlanDetails]?.purchasePreview
        );
      case Step.PaymentDetails:
        return !!state.data[Step.PaymentDetails];
      default:
        throw new Error(UNHANDLED_STEP_MESSAGE);
    }
  };

  const isStepActive: IGroupConversionFormContext['isStepActive'] = (step) => {
    switch (step) {
      case Step.ReviewPlanDetails:
        return true;
      case Step.PaymentDetails:
        return isStepSubmitted(Step.ReviewPlanDetails);
      default:
        throw new Error(UNHANDLED_STEP_MESSAGE);
    }
  };

  const onDirtyChange: IGroupConversionFormContext['onDirtyChange'] = (step, isDirty) => {
    dispatch({ type: 'set-dirty-step', step, isDirty });
  };

  const setStepData: IGroupConversionFormContext['setStepData'] = (step, data) => {
    if (!STEPS.includes(step)) {
      throw new Error(UNHANDLED_STEP_MESSAGE);
    }

    dispatch({ type: 'submit-step', step, data });
    Emitter.emit('step-submitted', { step });
  };

  const getNextStep: IGroupConversionFormContext['getNextStep'] = (step) => {
    const index = STEPS.findIndex((s) => s === step);
    return STEPS[index + 1] || null;
  };

  const submit = async () => {
    const token = await getRecurlyTokenPayload();
    convertGroup(
      {
        id,
        data: {
          billingInfoTokenId: token.id,
          couponCode: state.data[Step.ReviewPlanDetails]?.planSetup?.discountCode || undefined,
        },
      },
      {
        onSuccess: () => {
          Emitter.emit('form-submitted', undefined);
        },
      },
    );
  };

  const isFilled = !STEPS.map(isStepSubmitted).some((isSubmitted) => !isSubmitted);
  const isDirty = !!state.dirtySteps.length;

  return (
    <GroupConversionFormContext.Provider
      value={{
        formData: state.data,
        getNextStep,
        getStepData,
        isDirty,
        isFilled,
        isStepActive,
        isStepSubmitted,
        on: Emitter.subscribe.bind(Emitter),
        setStepData,
        onDirtyChange,
        isSubmitting,
        submit,
        email,
      }}
    >
      {children}
    </GroupConversionFormContext.Provider>
  );
};

const getInitialState = (params: {
  email: string;
  personalInformation: StepsData[Step.CompleteYourProfile]['information'];
  planSetup: PlanSetup;
}): RegistrationFormState => ({
  data: {
    [Step.CreateAnAccount]: {
      email: params.email,
      confirmEmail: params.email,
      confirmPassword: '',
      password: '',
    },
    [Step.CompleteYourProfile]: {
      information: params.personalInformation,
      occupation: prepareOccupationDetailsData(),
    },
    [Step.PaymentDetails]: null,
    [Step.ReviewPlanDetails]: {
      planSetup: params.planSetup,
      purchasePreview: null,
    },
  },
  dirtySteps: [],
});

export const useGroupConversionFormContext = () => {
  const context = useContext(GroupConversionFormContext);

  if (!context) {
    throw new Error('Hook cannot be used outside of GroupConversionFormContext');
  }

  return context;
};
