import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import {
  ALGORITHMS_FEATURE_LINK,
  CLINICAL_BRIEFS_FEATURE_LINK,
  DIC_FEATURE_LINK,
  FEATURES_LINK,
  MEDICATION_GUIDES_FEATURE_LINK,
  MONOGRAPHS_FEATURE_LINK,
  PATIENT_GUIDES_FEATURE_LINK,
} from 'src/constants/externalLinks';
import {
  ExtendedDropdownProps,
  NavigationExtendedDropdown,
  NavigationExtendedDropdownItem,
} from 'src/navigation/types';

import { ExtendedDropdown } from './ExtendedDropdown';

interface Props extends ExtendedDropdownProps {}

export const FeaturesExtendedDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation('navigation');

  const items: NavigationExtendedDropdownItem[][] = [
    [
      {
        icon: 'drug-monograph',
        title: t('drugs'),
        description: t('drugsDescription'),
        to: MONOGRAPHS_FEATURE_LINK,
        external: true,
      },
      {
        icon: 'medication-guide',
        title: t('drugHandouts'),
        description: t('drugHandoutsDescription'),
        to: MEDICATION_GUIDES_FEATURE_LINK,
        external: true,
      },
      {
        icon: 'dic',
        title: t('interactionChecker'),
        description: t('interactionCheckerDescription'),
        to: DIC_FEATURE_LINK,
        external: true,
      },
    ],
    [
      {
        icon: 'microscope',
        title: t('dxTx'),
        description: t('clinicalBriefsDescription'),
        pro: true,
        to: CLINICAL_BRIEFS_FEATURE_LINK,
        external: true,
      },
      {
        icon: 'stethoscope-text',
        title: t('clinicalHandouts'),
        description: t('clinicalHandoutsDescription'),
        pro: true,
        to: PATIENT_GUIDES_FEATURE_LINK,
        external: true,
      },
      {
        icon: 'algorithm',
        title: t('algorithms'),
        description: t('algorithmsDescription'),
        pro: true,
        to: ALGORITHMS_FEATURE_LINK,
        external: true,
      },
    ],
  ];

  const button = {
    title: t('seeAllFeatures'),
    to: FEATURES_LINK,
    external: true,
  };

  const data: NavigationExtendedDropdown = {
    content: {
      items: props.inMobileDropdown ? R.flatten(items) : items,
      button: props.inMobileDropdown ? button : undefined,
    },
    button: props.inMobileDropdown ? undefined : button,
  };

  return (
    <ExtendedDropdown
      wrapperStyle={[!props.inMobileDropdown && styles.wrapperDesktop]}
      {...data}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  wrapperDesktop: {
    width: 900,
  },
});
