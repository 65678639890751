import { Dispatch, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { Icon, Pressable } from 'src/components';
import { HELP_CENTER_LINK } from 'src/constants/externalLinks';
import { useUserInfo } from 'src/features/auth/hooks';
import { IntercomService } from 'src/features/intercom/IntercomService';
import { useAppcuesLaunchpad } from 'src/features/launchpad/hooks';
import { useDeviceInfo, useOutsideClickDetector } from 'src/hooks';
import { getShadow, palette } from 'src/styles';

import { LaunchpadButton } from './LaunchpadButton';
import { LaunchpadList } from './LaunchpadList';

interface Props {
  isDropdownOpen: boolean;
  setDropdownOpen: Dispatch<React.SetStateAction<boolean>>;
  toggleDropdown: () => void;
}
interface HelpButton extends React.ComponentProps<typeof LaunchpadButton> {
  key: string;
}

const LAUNCHPAD_LIST_ITEM_NUMBER_THRESHOLD = 3;

export const LaunchpadMenuDropdown: React.FC<Props> = ({
  isDropdownOpen,
  setDropdownOpen,
  toggleDropdown,
}) => {
  const { t } = useTranslation('navigation');
  const { isSmallestMobile, isDesktop } = useDeviceInfo();
  const {
    permissions: { canAccessIndividualAccountFeatures },
  } = useUserInfo();

  const { data, isLoading } = useAppcuesLaunchpad();
  const isLaunchpadListScrollable = data && data.length > LAUNCHPAD_LIST_ITEM_NUMBER_THRESHOLD;

  const wrapperRef = useRef<View>(null);

  const close = () => setDropdownOpen(false);

  useOutsideClickDetector(wrapperRef, close);

  const helpButtons: HelpButton[] = [
    {
      text: t('helpCenter'),
      link: HELP_CENTER_LINK,
      key: 'plumbs-help',
      testID: 'nav-button-help-center',
    },
    {
      text: t('contactSupport'),
      onPress: () => IntercomService.showMessenger(),
      key: 'plumbs-support',
      testID: 'nav-button-link-support',
    },
  ];

  return (
    <View ref={wrapperRef}>
      <Pressable onPress={toggleDropdown} testID="launchpad-dropdown-menu-btn" accessibilityLabel="Help">
        {(isHovered) => (
          <View
            style={[
              styles.button,
              isSmallestMobile ? styles.buttonSmallDevices : styles.buttonMediumDevices,
              isDesktop && styles.buttonLargeDevices,
              isHovered && styles.buttonHovered,
            ]}
          >
            <Icon
              name={isSmallestMobile ? 'help-small' : !isDesktop ? 'help-medium' : 'help'}
              color={isDropdownOpen || isHovered ? palette.navy : palette.blue}
              width={isSmallestMobile ? 13 : !isDesktop ? 15 : 16}
            />
          </View>
        )}
      </Pressable>
      {isDropdownOpen && (
        <View
          style={[
            styles.dropdown,
            isSmallestMobile ? styles.dropdownSmallDevices : styles.dropdownMediumDevices,
            isDesktop && styles.dropdownLargeDevices,
          ]}
          testID="launchpad-dropdown-menu"
        >
          {!!data?.length && canAccessIndividualAccountFeatures && (
            <LaunchpadList
              data={data}
              isLoading={isLoading}
              isLaunchpadListScrollable={!!isLaunchpadListScrollable}
            />
          )}

          <View
            style={[
              styles.helpButtonsWrapper,
              isLaunchpadListScrollable && styles.helpButtonsWrapperTopShadow,
            ]}
          >
            {helpButtons.map(({ text, link, key, testID, onPress }) => (
              <LaunchpadButton
                text={text}
                link={link}
                onPress={onPress}
                key={key}
                testID={testID}
                gradientBackground
                style={styles.helpButton}
              />
            ))}
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 20,
    backgroundColor: palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 15px 0px rgba(5, 144, 213, 0.20)',
    userSelect: 'none',
  },
  buttonLargeDevices: {
    width: 40,
    height: 40,
  },
  buttonMediumDevices: {
    width: 36,
    height: 36,
  },
  buttonSmallDevices: {
    width: 30,
    height: 30,
  },
  buttonHovered: {
    boxShadow: '0px 0px 20px 0px rgba(5, 144, 213, 0.25)',
  },
  dropdown: {
    width: 300,
    backgroundColor: palette.white,
    borderRadius: 5,
    top: 'calc(100% + 10px)',
    position: 'absolute',
    zIndex: 1,
    ...getShadow(0, 0.6, 20),
  },
  dropdownLargeDevices: {
    right: -52,
  },
  dropdownMediumDevices: {
    right: -42,
  },
  dropdownSmallDevices: {
    right: -36,
  },
  helpButtonsWrapper: {
    flex: 1,
    paddingTop: 12,
    paddingHorizontal: 16,
  },
  helpButtonsWrapperTopShadow: {
    boxShadow: '0px -20px 20px -20px rgba(23, 40, 94, 0.06)',
  },
  helpButton: {
    marginBottom: 12,
  },
});
