import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentListItem } from 'src/components';
import { ContentListItemCommonProps } from 'src/constants/types';

import type { Drug } from '../types';

type Props = ContentListItemCommonProps<Drug>;

export const DrugCard = React.memo<Props>(({ item, ...rest }) => {
  const { t } = useTranslation();

  return (
    <ContentListItem
      data={item}
      description={
        item.commercialNames?.length
          ? t('drugs:tradeNameDescription', { value: item.commercialNames.join(', ') })
          : undefined
      }
      {...rest}
    />
  );
});
