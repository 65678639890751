import { createContext, useContext } from 'react';

import { isObjectNotEmpty, showNotification } from 'src/helpers';
import { EventEmitterService } from 'src/services';

import { getErrorMessagesForSubscriptionForm } from '../helpers';
import { useRegisterAsGroupMember } from '../hooks';
import { isCompleteYourProfileDataFilled, isCreateAccountDataFilled } from './helpers';
import { RegistrationFormState, useRegistrationFormReducer } from './state';
import {
  RegistrationFormContext,
  RegistrationFormEvent,
  RegistrationStepsData,
  RegistrationFormStep as Step,
  RegistrationStepsData as StepsData,
} from './types';

interface IGroupInvitationRegistrationFormContext extends RegistrationFormContext {}

interface Props extends React.PropsWithChildren {
  email: string;
  memberId: string;
}

const Emitter = new EventEmitterService<RegistrationFormEvent>();
const UNHANDLED_STEP_MESSAGE = 'Provided Step is not handled in this Registration Form';
const STEPS = Object.freeze([Step.CreateAnAccount, Step.CompleteYourProfile]);

export const GroupInvitationRegistrationFormContext =
  createContext<IGroupInvitationRegistrationFormContext | null>(null);

/** Contains logic for registration form displayed to users invited to a group. Built upon RegistrationFormContext interface */
export const GroupInvitationRegistrationContextProvider: React.FC<Props> = ({
  children,
  email,
  memberId,
}) => {
  const [state, dispatch] = useRegistrationFormReducer(email, getInitialState);
  const { mutate: registerAsGroupMember, isPending: isSubmitting } = useRegisterAsGroupMember();

  const getStepData = <T extends Step>(step: T): StepsData[T] | null => {
    if (!STEPS.includes(step)) {
      throw new Error(UNHANDLED_STEP_MESSAGE);
    }
    return state.data[step];
  };

  const isStepSubmitted: IGroupInvitationRegistrationFormContext['isStepSubmitted'] = (step) => {
    switch (step) {
      case Step.CreateAnAccount:
        return isCreateAccountDataFilled(state.data[Step.CreateAnAccount]);
      case Step.CompleteYourProfile:
        return isCompleteYourProfileDataFilled(state.data[Step.CompleteYourProfile]);
      default:
        throw new Error(UNHANDLED_STEP_MESSAGE);
    }
  };

  const isStepActive: IGroupInvitationRegistrationFormContext['isStepActive'] = (step) => {
    switch (step) {
      case Step.CreateAnAccount:
        return true;
      case Step.CompleteYourProfile:
        return isStepSubmitted(Step.CreateAnAccount);
      default:
        throw new Error(UNHANDLED_STEP_MESSAGE);
    }
  };

  const onDirtyChange: IGroupInvitationRegistrationFormContext['onDirtyChange'] = (step, isDirty) => {
    dispatch({ type: 'set-dirty-step', step, isDirty });
  };

  const setStepData: IGroupInvitationRegistrationFormContext['setStepData'] = (step, data) => {
    if (!STEPS.includes(step)) {
      throw new Error(UNHANDLED_STEP_MESSAGE);
    }

    if (step === Step.CreateAnAccount) {
      /** Email cannot be changed */
      const submittedData = data as RegistrationStepsData[Step.CreateAnAccount];
      dispatch({
        type: 'submit-step',
        step: Step.CreateAnAccount,
        data: {
          email,
          confirmEmail: email,
          password: submittedData.password,
          confirmPassword: submittedData.confirmPassword,
        } as RegistrationStepsData[Step.CreateAnAccount],
      });
    } else {
      dispatch({ type: 'submit-step', step, data });
    }

    Emitter.emit('step-submitted', { step });

    if (step === Step.CompleteYourProfile) {
      // Submitting this step triggers submitting the whole form as well
      register(data as StepsData[Step.CompleteYourProfile]);
    }
  };

  const getNextStep: IGroupInvitationRegistrationFormContext['getNextStep'] = (step) => {
    const index = STEPS.findIndex((s) => s === step);
    return STEPS[index + 1] || null;
  };

  /** Accepts data from Complete Your Profile data as submitting this step and the whole form is trigerred by one button */
  const register = (profileData: StepsData[Step.CompleteYourProfile]) => {
    registerAsGroupMember(
      {
        data: {
          createAccount: state.data[Step.CreateAnAccount]!,
          completeYourProfile: profileData,
        },
        membershipId: memberId,
      },
      {
        onError: (error: any) => {
          const errors = error?.response?.data;

          if (!errors) {
            showNotification({ type: 'error', autoHide: true });
            return;
          }

          const { accountErrors, demographicsErrors, otherErrors, profileErrors } =
            getErrorMessagesForSubscriptionForm(errors);

          if (isObjectNotEmpty(accountErrors)) {
            Emitter.emit('step-error', { step: Step.CreateAnAccount, errors: accountErrors });
          }
          if (isObjectNotEmpty(profileErrors) || isObjectNotEmpty(demographicsErrors)) {
            Emitter.emit('step-error', {
              step: Step.CompleteYourProfile,
              errors: { ...profileErrors, ...demographicsErrors },
            });
          }
          if (isObjectNotEmpty(otherErrors)) {
            const messages = Object.values(otherErrors).join('\n');
            showNotification({ type: 'error', autoHide: false, title: messages || '' });
          }
        },
        onSuccess: () => {
          Emitter.emit('form-submitted', undefined);
        },
      },
    );
  };

  const submit = async () => {
    if (state.data[Step.CompleteYourProfile]) {
      register(state.data[Step.CompleteYourProfile]);
    }
  };

  const isFilled = !STEPS.map(isStepSubmitted).some((isSubmitted) => !isSubmitted);
  const isDirty = !!state.dirtySteps.length;

  return (
    <GroupInvitationRegistrationFormContext.Provider
      value={{
        formData: state.data,
        getNextStep,
        getStepData,
        isDirty,
        isFilled,
        isStepActive,
        isStepSubmitted,
        on: Emitter.subscribe.bind(Emitter),
        setStepData,
        onDirtyChange,
        isSubmitting,
        submit,
        email,
      }}
    >
      {children}
    </GroupInvitationRegistrationFormContext.Provider>
  );
};

const getInitialState = (email: string): RegistrationFormState => ({
  data: {
    [Step.CreateAnAccount]: {
      email,
      confirmEmail: email,
      password: '',
      confirmPassword: '',
    },
    [Step.CompleteYourProfile]: null,
    [Step.PaymentDetails]: null,
    [Step.ReviewPlanDetails]: null,
  },
  dirtySteps: [],
});

export const useGroupInvitationRegistrationFormContext = () => {
  const context = useContext(GroupInvitationRegistrationFormContext);

  if (!context) {
    throw new Error('Hook cannot be used outside of GroupInvitationRegistrationFormContext');
  }

  return context;
};
