export const CLINICIANS_BRIEFS_SITE_LINK = 'https://www.cliniciansbrief.com/';
export const MARKETING_SITE_LINK = 'https://plumbs.com';
export const VETMEDUX_SITE_LINK = 'https://vetmedux.com/';
export const HELP_CENTER_LINK = 'https://intercom.help/plumbs';

export const CONTACT_US_LINK = `${MARKETING_SITE_LINK}/about/contact-us`;
export const CONTACT_SALES_LINK = `${MARKETING_SITE_LINK}/about/contact-us/contact-sales`;
export const PRIVACY_POLICY_LINK = `${MARKETING_SITE_LINK}/privacy-policy`;
export const ACCEPTABLE_USE_POLICY_LINK = `${MARKETING_SITE_LINK}/acceptable-use-policy`;
export const TERMS_AND_CONDITIONS_LINK = `${MARKETING_SITE_LINK}/terms-conditions`;
export const INSTINCT_WEBSITE_LINK = 'https://www.instinct.vet';
export const EULA_LINK = `${MARKETING_SITE_LINK}/eula`;
export const ABOUT_LINK = `${MARKETING_SITE_LINK}/about`;
export const OUR_STORY_LINK = `${MARKETING_SITE_LINK}/about/our-story`;
export const BLOG_LINK = `${MARKETING_SITE_LINK}/blog`;
export const HANDBOOK_ERRATA_LINK = `${MARKETING_SITE_LINK}/handbook-errata`;
export const BUY_HANDBOOK_LINK = `${MARKETING_SITE_LINK}/buy-the-handbook`;
export const WHY_PLUMBS_LINK = `${MARKETING_SITE_LINK}/why-plumbs`;
export const FEATURES_LINK = `${MARKETING_SITE_LINK}/features`;
export const MONOGRAPHS_FEATURE_LINK = `${MARKETING_SITE_LINK}/features/drug-monographs`;
export const MEDICATION_GUIDES_FEATURE_LINK = `${MARKETING_SITE_LINK}/features/medication-guides`;
export const DIC_FEATURE_LINK = `${MARKETING_SITE_LINK}/features/drug-interaction-checker`;
export const ALGORITHMS_FEATURE_LINK = `${MARKETING_SITE_LINK}/features/algorithms`;
export const CLINICAL_BRIEFS_FEATURE_LINK = `${MARKETING_SITE_LINK}/features/clinical-briefs`;
export const PATIENT_GUIDES_FEATURE_LINK = `${MARKETING_SITE_LINK}/features/patient-guides`;
export const PRICING_LINK = `${MARKETING_SITE_LINK}/pricing`;
export const DEMO_LINK = `${MARKETING_SITE_LINK}/demo`;
export const OUR_TEAM_LINK = `${MARKETING_SITE_LINK}/about/our-team`;
export const SOLUTIONS_LINK = `${MARKETING_SITE_LINK}/solutions`;
export const VETERINARY_PRO_SOLUTIONS_LINK = `${MARKETING_SITE_LINK}/solutions/veterinary-professionals`;
export const VETERINARY_PRACTICES_SOLUTIONS_LINK = `${MARKETING_SITE_LINK}/solutions/veterinary-practices`;
export const PHARMACISTS_SOLUTIONS_LINK = `${MARKETING_SITE_LINK}/solutions/pharmacists`;
export const STUDENTS_SOLUTIONS_LINK = `${MARKETING_SITE_LINK}/solutions/students`;
export const UNIVERSITIES_SOLUTIONS_LINK = `${MARKETING_SITE_LINK}/solutions/universities`;
export const ACADEMIC_SOLUTIONS_LINK = `${MARKETING_SITE_LINK}/solutions/academic-institutions/`;
export const CLINICAL_CHALLENGE_FORM_LINK = 'https://cliniciansbrief.wufoo.com/forms/s1donyiw0v95ztq/';
export const GO_PRO_LINK = `${MARKETING_SITE_LINK}/go-pro`;
export const DRUG_INFORMATION_LINK = `${MARKETING_SITE_LINK}/why-plumbs/drug-information`;
export const DIAGONISTIC_TREATMENT_INFORMATION_LINK = `${MARKETING_SITE_LINK}/why-plumbs/diagnostic-treatment-information`;
export const PET_OWNER_EDUCATION_LINK = `${MARKETING_SITE_LINK}/why-plumbs/pet-owner-education`;
export const PERSONALIZATION_TOOLS_LINK = `${MARKETING_SITE_LINK}/why-plumbs/personalization-tools`;
export const PVD_SOLUTION_LINK = `${MARKETING_SITE_LINK}/solutions/plumbs-veterinary-drugs/`;
export const PRO_SOLUTION_LINK = `${MARKETING_SITE_LINK}/solutions/plumbs-pro/`;

export const SUPPORT_LINK = HELP_CENTER_LINK;
export const HELP_UPGRADE_PLAN_LINK = `${HELP_CENTER_LINK}/articles/9681781-what-does-the-session-limit-mean`;
export const HELP_CANCEL_SUBSCRIPTION_LINK = `${HELP_CENTER_LINK}/articles/9681778-how-do-i-cancel-my-subscription`;
export const GETTING_STARTED_LINK = `${HELP_CENTER_LINK}/collections/10046824-getting-started`;

export const FACEBOOK_LINK = 'https://www.facebook.com/PlumbsVet';
export const TWITTER_LINK = 'https://twitter.com/plumbsvetdrugs';

export const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=com.plumbs.app';
export const IOS_APP_LINK = 'https://apps.apple.com/us/app/plumbs/id1556729700';

export const APPCUES_PRO_TOUR_FLOW_ID = 'bd2fdf75-9e3a-44d6-90a4-f3bb23811075';
