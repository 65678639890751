import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { DropdownWithHeader, MobileContentHeader } from 'src/components';
import { ContentCardLocation, DropdownItem } from 'src/constants/types';
import { type HomeScreenContentBox as IHomeScreenContentBox } from 'src/features/home/components/HomeScreenContentBox';
import { isWeb } from 'src/helpers';
import { useContainer, useDeviceInfo } from 'src/hooks';
import { usePersistentState } from 'src/hooks/usePersistentState';
import { CONTAINER_PADDING_H_MOBILE, ifWeb } from 'src/styles';

import { ViewedContentList } from './ViewedContentList';
import { useViewedContent } from '../hooks';
import { ViewedSortOrder } from '../types';

interface Props {
  /** Passed in props to avoid circular imports */
  HomeScreenContentBox: React.ComponentType<IHomeScreenContentBox>;
}

export const ViewedContentBox: React.FC<Props> = ({ HomeScreenContentBox }) => {
  const { t } = useTranslation('recentlyViewed');
  const containerStyle = useContainer();
  const { isTablet } = useDeviceInfo();

  const recentlyViewed = useViewedContent(ViewedSortOrder.RecentlyViewed);
  const topViewed = useViewedContent(ViewedSortOrder.TopViewed);

  const areThereAnyRecentlyViewed = (recentlyViewed.data?.length || 0) > 0;
  const areThereAnyTopViewed = (topViewed.data?.length || 0) > 0;

  const dropdownItems: DropdownItem<ViewedSortOrder>[] = [
    {
      label: t('topViewed'),
      value: ViewedSortOrder.TopViewed,
      isHidden: !areThereAnyTopViewed,
    },
    {
      label: t('recentlyViewed'),
      value: ViewedSortOrder.RecentlyViewed,
      // we don't hide if list is not fetched yet to display a title above loading spinner
      isHidden:
        areThereAnyTopViewed &&
        !areThereAnyRecentlyViewed &&
        !recentlyViewed.isLoading &&
        !recentlyViewed.isError,
    },
  ];

  const initialValue = areThereAnyRecentlyViewed
    ? ViewedSortOrder.RecentlyViewed
    : ViewedSortOrder.TopViewed;

  const [selectedSorting = ViewedSortOrder.RecentlyViewed, setSortOrder] = usePersistentState(
    'recently-viewed-sort-order',
    initialValue,
  );

  useLayoutEffect(() => {
    if (areThereAnyRecentlyViewed && !areThereAnyTopViewed) {
      setSortOrder(ViewedSortOrder.RecentlyViewed);
    } else if (areThereAnyTopViewed && !areThereAnyRecentlyViewed) {
      setSortOrder(ViewedSortOrder.TopViewed);
    }
  }, [areThereAnyTopViewed, areThereAnyRecentlyViewed, setSortOrder]);

  const list = selectedSorting === ViewedSortOrder.RecentlyViewed ? recentlyViewed : topViewed;
  const location: ContentCardLocation =
    selectedSorting === ViewedSortOrder.RecentlyViewed ? 'recently-viewed' : 'top-viewed';

  const isOnlyOneFilterAvailable =
    1 ===
    dropdownItems.reduce((numberOfVisibleItems, item) => {
      if (!item.isHidden) {
        return numberOfVisibleItems + 1;
      }
      return numberOfVisibleItems;
    }, 0);

  const headerStyles = [styles.header, !isTablet && styles.headerMobile, !isWeb && containerStyle];

  const header = isOnlyOneFilterAvailable ? (
    <MobileContentHeader style={headerStyles}>
      {dropdownItems.find((item) => !item.isHidden)?.label}
    </MobileContentHeader>
  ) : (
    <View style={headerStyles}>
      <DropdownWithHeader
        items={dropdownItems}
        getTitle={(item) => item?.label ?? ''}
        value={selectedSorting}
        onChange={(item) => item.value && setSortOrder(item.value)}
      />
    </View>
  );

  return (
    <HomeScreenContentBox Header={header} fetchStatus={list.fetchStatus} error={list.error}>
      {!!list.data && <ViewedContentList content={list.data} location={location} />}
    </HomeScreenContentBox>
  );
};

const styles = StyleSheet.create({
  header: {
    marginBottom: 12,
    alignItems: 'flex-start',
    zIndex: 1,
  },
  headerMobile: {
    ...ifWeb({
      paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    }),
  },
});
