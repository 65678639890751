import { StyleSheet, View, ViewStyle, StyleProp } from 'react-native';

import { featureButtonColors } from 'src/constants/content';
import { IconName } from 'src/constants/types';
import { isNodeEmpty } from 'src/helpers/functions';
import { Link } from 'src/navigation/components';
import { ifWeb, palette, typography } from 'src/styles';

import { Icon } from './Icon/Icon';
import { Pressable } from './Pressable';
import { StyledText } from './StyledText';

export interface FeatureButtonProps extends React.PropsWithChildren {
  text: string;
  icon: IconName;
  iconStyle?: StyleProp<ViewStyle>;
  iconWidth?: number;
  onPress?: () => void;
  linkProps?: React.ComponentProps<typeof Link>;
  iconColor?: string;
  hidden?: boolean;
  testID?: string;
  style?: StyleProp<ViewStyle>;
}

export const FeatureButton: React.FC<FeatureButtonProps> = ({
  text,
  onPress,
  icon,
  iconStyle,
  iconWidth = 12,
  linkProps,
  iconColor = palette.blue,
  testID,
  style,
  children,
}) => {
  const content = (isHovered: boolean, isPressed: boolean) => {
    const isActive = isHovered || isPressed;
    const colors = featureButtonColors[isActive ? 'active' : 'default'];

    return (
      <View style={[styles.wrapper, { borderColor: colors.primary }, style]} testID={testID}>
        <View style={styles.button}>
          <StyledText style={[styles.text, { color: colors.content }]}>{text}</StyledText>
          <View style={[styles.iconWrapper, { backgroundColor: iconColor }]}>
            <Icon name={icon} style={iconStyle} width={iconWidth} color={palette.white} />
          </View>
        </View>
        {!isNodeEmpty(children) && <View style={styles.contentWrapper}>{children}</View>}
      </View>
    );
  };

  return linkProps ? (
    <Link {...linkProps} onPress={onPress || linkProps.onPress}>
      {content}
    </Link>
  ) : (
    <Pressable onPress={onPress}>{content}</Pressable>
  );
};

const styles = StyleSheet.create({
  text: {
    paddingRight: 8,
    flex: 1,
    justifyContent: 'space-between',
    ...typography.body3Bold,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  wrapper: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderRadius: 5,
    ...ifWeb({
      transitionProperty: 'border-color',
    }),
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentWrapper: {
    marginTop: 16,
  },
  iconWrapper: {
    width: 28,
    height: 28,
    borderRadius: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
