import React, { useState } from 'react';

import { ProPaywallModal } from 'src/components';
import { ContentItemQuickLink, ContentType } from 'src/constants/types';
import { useUserInfo } from 'src/features/auth/hooks';

interface Response {
  isContentBlocked: boolean;
  paywallElement: React.ReactNode;
  onQuickLinkPress?: (quickLink: ContentItemQuickLink) => void;
  openPaywall: () => void;
  onCardPress?: () => void;
}

interface Params {
  contentType: ContentType;
  onQuickLinkPress?: (quickLink: ContentItemQuickLink) => void;
}

export const usePaywallInContentCard = ({ contentType, onQuickLinkPress }: Params): Response => {
  const { hasAccessToContentType } = useUserInfo();
  const [isPaywallOpen, setPaywall] = useState(false);

  const isContentBlocked = !hasAccessToContentType(contentType);

  return {
    isContentBlocked,
    paywallElement: isPaywallOpen ? <ProPaywallModal close={() => setPaywall(false)} /> : null,
    onCardPress: isContentBlocked ? () => setPaywall(true) : undefined,
    onQuickLinkPress: isContentBlocked ? () => setPaywall(true) : onQuickLinkPress,
    openPaywall: () => setPaywall(true),
  };
};
