import { StyleSheet, View } from 'react-native';

import { ContentStatus, ContentType } from 'src/constants/types';
import { ifWeb, palette } from 'src/styles';

import { ContentTypeBadge } from '../ContentTypeBadge';
import { Icon } from '../Icon/Icon';
import { ListItem } from '../ListItem';

interface Props
  extends Pick<
    React.ComponentProps<typeof ListItem>,
    | 'title'
    | 'linkProps'
    | 'onPress'
    | 'bottomBorder'
    | 'topBorder'
    | 'sideBorder'
    | 'roundedBottomBorder'
    | 'roundedTopBorder'
  > {
  contentType: ContentType;
  inDevelopment?: boolean;
  testID?: string;
  isNew?: boolean;
}

export const ContentListItemCardSimplified: React.FC<Props> = ({
  contentType,
  inDevelopment,
  ...listItemProps
}) => {
  return (
    <ListItem
      {...listItemProps}
      style={styles.listItem}
      rightColumn={
        <View style={styles.rightColumn}>
          <View style={styles.badge}>
            <ContentTypeBadge
              contentType={contentType}
              statusType={inDevelopment ? ContentStatus.InDevelopment : undefined}
            />
          </View>
          <Icon name="chevron-right" color={inDevelopment ? palette.grey4 : palette.navy} width={10} />
        </View>
      }
      textHoveredStyle={styles.listItemHovered}
      textStyle={inDevelopment && styles.inDevelopmentText}
    />
  );
};

const styles = StyleSheet.create({
  listItem: {
    backgroundColor: palette.white,
  },
  listItemHovered: {
    ...ifWeb({
      color: palette.blue,
      transitionProperty: 'color',
    }),
  },
  rightColumn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  badge: {
    marginRight: 13,
    marginLeft: 8,
  },
  inDevelopmentText: {
    color: palette.grey5,
  },
});
