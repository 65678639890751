import React, { useEffect } from 'react';

import { ContentListScreen, ProInfoBanner } from 'src/components';
import { ContentListType, ContentType } from 'src/constants/types';
import { SearchContentTypeFilter } from 'src/features/search/enums';
import { OlyticsService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { DDxCard } from '../components/DDxCard';

type Props = ScreenProps<Route.DDxList>;

export const DDxListScreen: React.FC<Props> = ({ route, navigation }) => {
  useEffect(() => {
    OlyticsService.fire();
  }, []);

  return (
    <ContentListScreen
      title={i18n.t('screens:ddx')}
      titleInUppercase={false}
      route={route}
      navigation={navigation}
      searchFilter={SearchContentTypeFilter.DDx}
      contentListType={ContentListType.DDx}
      ContentCardComponent={DDxCard}
    >
      <ProInfoBanner contentTypes={[ContentType.DDx]} />
    </ContentListScreen>
  );
};
