import { useEffect } from 'react';

import { ContentListScreen } from 'src/components';
import { ContentListType } from 'src/constants/types';
import { SearchContentTypeFilter } from 'src/features/search/enums';
import { OlyticsService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { AppendixCard } from '../components';

interface Props extends ScreenProps<Route.AppendixList> {}

export const AppendixListScreen: React.FC<Props> = ({ route, navigation }) => {
  useEffect(() => {
    OlyticsService.fire();
  }, []);

  return (
    <ContentListScreen
      title={i18n.t('appendix:header')}
      route={route}
      navigation={navigation}
      searchFilter={SearchContentTypeFilter.Appendix}
      contentListType={ContentListType.Appendix}
      ContentCardComponent={AppendixCard}
    />
  );
};
