import { ContentType } from 'src/constants/types';
import type { UserPermissions } from 'src/features/auth/types';
import {
  clinicalBriefsContentTypes,
  clinicalHandoutsContentTypes,
  drugHandoutContentTypes,
} from 'src/helpers';
import { i18n } from 'src/locale';

import { SearchContentTypeFilter } from './enums';

export const getContentTypeSearchParam = (mainFilter: SearchContentTypeFilter): ContentType[] => {
  switch (mainFilter) {
    case SearchContentTypeFilter.Algorithm:
      return [ContentType.Algorithm];
    case SearchContentTypeFilter.Drug:
      return [ContentType.Drug];
    case SearchContentTypeFilter.DxTx:
      return clinicalBriefsContentTypes;
    case SearchContentTypeFilter.Handout:
      return [...drugHandoutContentTypes, ...clinicalHandoutsContentTypes];
    case SearchContentTypeFilter.Appendix:
      return [ContentType.Appendix];
    case SearchContentTypeFilter.DDx:
      return [ContentType.DDx];
    case SearchContentTypeFilter.All:
      return [];
  }
};

export const getPlaceholderByFilter = (
  filter: SearchContentTypeFilter,
  { hasFullAccessToClinicalHandouts }: UserPermissions,
): string => {
  switch (filter) {
    case SearchContentTypeFilter.Algorithm:
      return i18n.t('search:placeholder:algorithms');
    case SearchContentTypeFilter.Handout:
      return i18n.t(`search:placeholder:${hasFullAccessToClinicalHandouts ? 'handouts' : 'basicHandouts'}`);
    case SearchContentTypeFilter.Drug:
      return i18n.t('search:placeholder:drugs');
    case SearchContentTypeFilter.DxTx:
      return i18n.t('search:placeholder:dxtx');
    case SearchContentTypeFilter.Appendix:
      return i18n.t('search:placeholder:appendix');
    case SearchContentTypeFilter.DDx:
      return i18n.t('search:placeholder:ddx');
    case SearchContentTypeFilter.All:
      return i18n.t('search:placeholder:all');
  }
};
