import React, { useEffect } from 'react';

import { ContentListScreen, ProInfoBanner } from 'src/components';
import { ContentListType, ContentType } from 'src/constants/types';
import { SearchContentTypeFilter } from 'src/features/search/enums';
import { OlyticsService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { AlgorithmCard } from '../components';

export type Props = ScreenProps<Route.Algorithms>;

export const AlgorithmsScreen: React.FC<Props> = ({ route, navigation }) => {
  useEffect(() => {
    OlyticsService.fire();
  }, []);

  return (
    <ContentListScreen
      title={i18n.t('screens:algorithms')}
      route={route}
      navigation={navigation}
      ContentCardComponent={AlgorithmCard}
      searchFilter={SearchContentTypeFilter.Algorithm}
      contentListType={ContentListType.Algorithms}
    >
      <ProInfoBanner contentTypes={[ContentType.Algorithm]} />
    </ContentListScreen>
  );
};
