import { client } from 'src/api/client';
import { convertObjectToSnakeCase } from 'src/helpers/functions';

import {
  Purchase,
  PurchasePreviewPayload,
  BillingInformation,
  PurchaseSubscriptionAsNewUserPayload,
  PurchaseSubscriptionAsExistingUserPayload,
  ChangePlanPayload,
  GroupInvitationValidationPayload,
  AcceptGroupInvitationPayload,
  AcceptGroupInvitationResponse,
  RegisterAsGroupMemberPayload,
  SubscriptionStatusInfo,
  SubscriptionResponse,
  GroupInvitationValidationResponse,
  CheckEmailPayload,
  SubscriptionInvitationInfoResponse,
  GroupConversionInfo,
  ConvertGroupPayload,
  PromotionSetup,
  GetBillingInformationResponse,
  InvoicesListFetchParams,
  InvoicesListFetchResponse,
  ApplyPromotionParams,
} from './types';

export const getPurchasePreview = async (data: PurchasePreviewPayload) => {
  return client.request<Purchase>({
    url: `/subscriptions/preview-purchase/`,
    method: 'POST',
    data,
    omitAuth: true,
  });
};

export const VALIDATE_EMAIL_AND_PASSWORD_URL = '/subscriptions/check-email/';

export const validateEmailAndPassword = async (data: CheckEmailPayload) => {
  const response = await client.request({
    method: 'POST',
    url: VALIDATE_EMAIL_AND_PASSWORD_URL,
    omitAuth: true,
    data,
  });

  return response.data;
};

export const cancelSubscription = async () => {
  return await client.request({
    url: '/subscriptions/cancel/',
    method: 'PUT',
  });
};

export const renewSubscription = async () => {
  return await client.request({
    url: '/subscriptions/renew/',
    method: 'PUT',
  });
};

export const fetchSubscriptionInfo = async () => {
  const response = await client.request<SubscriptionStatusInfo>({
    url: `/subscriptions/subscription/`,
    method: 'GET',
  });

  return response.data;
};

export const fetchBillingInformation = async () => {
  const response = await client.request<GetBillingInformationResponse>({
    method: 'GET',
    url: '/subscriptions/billing-info/',
  });

  return response.data;
};

export const updateBillingInformation = async (tokenId: string) => {
  return client.request<BillingInformation>({
    url: `/subscriptions/billing-info/`,
    method: 'PUT',
    data: convertObjectToSnakeCase({ tokenId: tokenId }),
  });
};

export const purchaseSubscription = async (data: PurchaseSubscriptionAsNewUserPayload) => {
  const response = await client.request<SubscriptionResponse>({
    url: '/subscriptions/purchase/',
    method: 'POST',
    data,
    omitAuth: true,
  });

  return response.data;
};

export const purchaseSubscriptionAsExistingUser = async (
  data: PurchaseSubscriptionAsExistingUserPayload,
) => {
  return client.request({
    url: '/subscriptions/purchase-plan/',
    method: 'POST',
    data,
  });
};

export const changePlan = async (data: ChangePlanPayload) => {
  return client.request({
    url: '/subscriptions/change-plan/',
    method: 'POST',
    data,
  });
};

export const validateGroupInvitation = async ({ memberId, email }: GroupInvitationValidationPayload) => {
  const response = await client.request<GroupInvitationValidationResponse>({
    method: 'GET',
    url: `/groups/invitation/verify/${memberId}/${email}/`,
    omitAuth: true,
  });

  return response.data;
};

export const acceptGroupInvitation = async (isSignedIn: boolean, params: AcceptGroupInvitationPayload) => {
  const response = await client.request<AcceptGroupInvitationResponse>({
    url: '/groups/join/',
    method: 'POST',
    data: params,
    omitAuth: !isSignedIn,
  });

  return response.data;
};

export const registerAsGroupMember = async (params: RegisterAsGroupMemberPayload) => {
  const response = await client.request<SubscriptionResponse>({
    url: '/accounts/user/create-account/',
    method: 'POST',
    data: params,
    omitAuth: true,
  });

  return response.data;
};

export const fetchInvoicesList = async (params: InvoicesListFetchParams) => {
  const response = await client.request<InvoicesListFetchResponse>({
    url: `/subscriptions/invoices/`,
    method: 'GET',
    params: convertObjectToSnakeCase(params, true),
  });

  return response.data;
};

export const fetchInvoiceData = async (invoiceId: string) => {
  const response = await client.request<string>({
    url: `/subscriptions/invoices/${invoiceId}/`,
    method: 'GET',
  });

  return response.data;
};

export const verifySubscriptionInvitation = async (invitationId: string) => {
  const response = await client.request<SubscriptionInvitationInfoResponse>({
    url: `/subscriptions/subscription-invitation/${invitationId}/`,
    method: 'GET',
    omitAuth: true,
  });

  return response.data;
};

export const fetchGroupConversionData = async (id: string) => {
  const response = await client.request<GroupConversionInfo>({
    url: `/groups/conversion/${id}/`,
    method: 'GET',
  });

  return response.data;
};

export const convertManagedGroup = async ({ id, data }: ConvertGroupPayload) => {
  return client.request({
    url: `/groups/conversion/${id}/`,
    method: 'POST',
    data,
  });
};

export const getPromotionSetup = async (promoId: string, userId: string, loggedIn: boolean) => {
  const resp = await client.request<PromotionSetup>({
    method: 'GET',
    url: `/sales/promotion/${promoId}/${userId}/`,
    omitAuth: !loggedIn,
  });

  return resp.data;
};

export const applyPromotionForExistingUser = async ({
  promotionId,
  userIdentifier,
  ...payload
}: ApplyPromotionParams) => {
  const resp = await client.request<PromotionSetup>({
    method: 'POST',
    url: userIdentifier
      ? `/sales/promotion/${promotionId}/${userIdentifier}/`
      : `/sales/promotion/${promotionId}/`,
    data: payload,
  });

  return resp.data;
};
