import { ContentCardLocation } from 'src/constants/types';
import { isDrugHandout } from 'src/helpers';

import { ClinicalHandoutContentType, DrugHandoutContentType, HandoutsLanguageType } from './types';
import { MixpanelHandoutLanguage, MixpanelSelectHandoutParams } from '../tracking';

/**
 * @throws Throws an error if we don't have any params defined for given `location`
 */
export const getSelectHandoutMixpanelParams = (
  location: ContentCardLocation,
  contentType: ClinicalHandoutContentType | DrugHandoutContentType,
): MixpanelSelectHandoutParams => {
  const isItemDrugHandout = isDrugHandout({ contentType });
  const indexLocation = isItemDrugHandout ? 'VMG Index' : 'Clinical Handouts Index';
  switch (location) {
    case 'content-list': {
      return {
        location: indexLocation,
      };
    }
    case 'content-list-new': {
      return {
        location: indexLocation,
        subLocation: 'New',
      };
    }
    case 'content-list-favorites': {
      return {
        location: indexLocation,
        subLocation: 'Favorites',
      };
    }
    case 'content-list-updated': {
      return {
        location: indexLocation,
        subLocation: 'Updated',
      };
    }
    case 'search-list': {
      return {
        location: 'Search Results Page',
      };
    }
    case 'top-viewed': {
      return {
        location: 'Homepage',
        subLocation: 'Top Viewed',
      };
    }
    case 'recently-viewed': {
      return {
        location: 'Homepage',
        subLocation: 'Recently Viewed',
      };
    }
    case 'drug-page': {
      return {
        location: 'Drug Page',
      };
    }
    case 'dx-tx-page': {
      return {
        location: 'Dx & Tx Page',
      };
    }
    default: {
      throw new Error(
        `Couldn't set Select Handout Mixpanel properties for given params: ${location} location, ${contentType} content type`,
      );
    }
  }
};

export const getHandoutsMixpanelLanguage = (language: HandoutsLanguageType): MixpanelHandoutLanguage => {
  switch (language) {
    case HandoutsLanguageType.English:
      return 'English';
    case HandoutsLanguageType.Spanish:
      return 'Spanish';
    case HandoutsLanguageType.SpanishEnglish:
      return 'English & Spanish';
  }
};
