import { useCallback } from 'react';
import { View } from 'react-native';

import { HEADING_DATASET_KEY } from 'src/constants/constants';
import { ContentItemDetails, ContentItemSection } from 'src/constants/types';
import { type AccordionRef } from 'src/constants/types/accordion';
import { findSectionWithHeader } from 'src/helpers';
import { useNavigation } from 'src/navigation/hooks/useNavigation';

type SectionsRefs = {
  [key: string]: React.RefObject<View>;
};

interface Params {
  item: ContentItemDetails;
  referenceDivRef: React.RefObject<View>;
  accordionRef: React.RefObject<AccordionRef>;
  sectionsRefs: SectionsRefs;
  sections: ContentItemSection[];
}

/** Set of utils designed to open and scroll to a specific section or subsection
 * within Content Item screen.
 * Web only. */
export const useSectionScrolling = ({ sectionsRefs, referenceDivRef, accordionRef, sections }: Params) => {
  const navigation = useNavigation();

  const scrollToElement = useCallback(
    (element: Element | null) => {
      const referenceDiv = referenceDivRef.current as unknown as HTMLDivElement;
      if (element && referenceDiv) {
        const top = referenceDiv.offsetTop;
        const refDivY = referenceDiv.getBoundingClientRect().top;
        const sectionY = element.getBoundingClientRect().top;

        window.scrollTo(0, sectionY - refDivY - top);
      }
    },
    [referenceDivRef],
  );

  const scrollToSection = useCallback(
    (id: string) => {
      if (!(id in sectionsRefs)) return;
      const sectionRef = sectionsRefs[id];
      scrollToElement(sectionRef.current as unknown as HTMLDivElement);
    },
    [sectionsRefs, scrollToElement],
  );

  const openAndScrollToSection = useCallback(
    (sectionId: string) => {
      accordionRef.current?.expandItem(sectionId);

      // adding a little delay to make sure section is open before calculating it's position
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          scrollToSection(sectionId);
          resolve();
        }, 70);
      });
    },
    [scrollToSection, accordionRef],
  );

  const scrollToSectionAndSubsection = useCallback(
    async (section?: string, subsection?: string) => {
      const sectionId = section || (subsection ? findSectionWithHeader(sections, subsection)?.id : '');

      if (!sectionId) {
        return;
      }
      await openAndScrollToSection(sectionId);
      if (subsection) {
        const sectionElement = sectionsRefs[sectionId]?.current as unknown as HTMLDivElement | undefined;
        const headerElement = sectionElement?.querySelector(
          `[data-${HEADING_DATASET_KEY}="${subsection}"]`,
        );
        if (headerElement) {
          scrollToElement(headerElement);
        }
      }
      /** Resetting the params so user is able to click on a link with subsection again */
      navigation.setParams({ section: undefined, subsection: undefined });
    },
    [navigation, sections, openAndScrollToSection, scrollToElement, sectionsRefs],
  );

  return {
    openAndScrollToSection,
    scrollToSectionAndSubsection,
  };
};
